import React, { useEffect } from 'react';

import PageContainer from '../../components/common/PageContainer/PageContainer';
import { MyWalletForm } from '../../components/forms/MyWalletForm/MyWalletForm';

export default function MyWallet() {
    return (
        <PageContainer>
            <MyWalletForm />
        </PageContainer>
    );
}
