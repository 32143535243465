import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import Error from '../../../../common/Error/Error';
import Risk from '../../../../common/Risk/Risk';
import { CheckBox } from '../../../../common/CheckBox/CheckBox';
import { Table } from '../../../../common/Table/Table';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import { useGetFirstStepDataQuery } from '../../../../../redux/services/myWalletFormApi';
import { saveFormValues as saveParametersValues } from '../../../../../redux/slices/myWalletParametersFormSlice';
import { formValues, saveChosenFundsData, saveFormValues } from '../../../../../redux/slices/myWalletFormSlice';
import { changeMyWalletStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { validationChooseWallet } from './validation';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import validationContent from '../../../validation.json';
import content from '../../content.json';
import styles from './MyWalletChooseWalletForm.module.scss';

export default function MyWalletChooseWalletForm(props) {
    const { step, setLoading } = props;
    const initValues = useSelector(formValues);
    const { data, isLoading: isDataLoading, isSuccess, isError } = useGetFirstStepDataQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isDataLoading) {
            setLoading(true);
        } else if (isSuccess || isError) {
            setLoading(false);
            responsiveIFrame();
        }
    }, [isDataLoading, isSuccess, isError]);

    const onFormSubmit = (values) => {
        dispatch(
            saveFormValues({
                ...values,
            })
        );

        // if user comes back and changes the funds, 2 step must be reset
        dispatch(saveParametersValues(null));

        const chosenFunds = data.filter((fund) => values.chosenFundsExternalIds.includes(fund.externalId.toString()));

        dispatch(saveChosenFundsData(chosenFunds));
        dispatch(changeMyWalletStep(1));
    };

    return (
        <FormContainer customClass="myWalletFirstStep" title='Wybierz fundusze'>
            <Formik enableReinitialize initialValues={initValues} validationSchema={validationChooseWallet} onSubmit={onFormSubmit}>
                {({ errors, values, isValid, setFieldValue }) => {
                    return (
                        <>
                            {data && (
                                <Form>
                                    <div className={styles.subHeader}>{content.chooseWalletSubHeader}</div>
                                    <FieldWrapper error={errors.chosenFundsExternalIds}>
                                        <Table responsive>
                                            <Table.Tr head>
                                                <Table.Td>Nazwa funduszu/subfunduszu</Table.Td>
                                                <Table.Td alignCenter>Kategoria jednostek</Table.Td>
                                                <Table.Td alignCenter>Kategoria subfunduszu</Table.Td>
                                                <Table.Td alignCenter>Wartość j.u.</Table.Td>
                                                <Table.Td alignCenter>Waluta</Table.Td>
                                                <Table.Td alignCenter>Data notowania</Table.Td>
                                                <Table.Td alignCenter>Profil ryzyka i zysku</Table.Td>
                                            </Table.Tr>

                                            {data.map((fund) => (
                                                <Table.Tr key={fund.externalId}>
                                                    <Table.Td>
                                                        <CheckBox
                                                            Field={
                                                                <Field
                                                                    type='checkbox'
                                                                    name='chosenFundsExternalIds'
                                                                    value={fund.externalId}
                                                                    checked={values.chosenFundsExternalIds.includes(fund.externalId.toString())}
                                                                    disabled={
                                                                        !values.chosenFundsExternalIds.includes(fund.externalId.toString()) &&
                                                                        values.chosenFundsExternalIds.length === 15
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            <div className={styles.walletLabel}>{fund.name}</div>
                                                        </CheckBox>
                                                    </Table.Td>
                                                    <Table.Td alignCenter>{fund.class}</Table.Td>
                                                    <Table.Td alignCenter>{fund.category}</Table.Td>
                                                    <Table.Td alignCenter>{fund.priceValue}</Table.Td>
                                                    <Table.Td alignCenter>{fund.currency}</Table.Td>
                                                    <Table.Td alignCenter>{new Date(fund.priceDate).toLocaleDateString('pl-PL')}</Table.Td>
                                                    <Table.Td alignCenter>
                                                        <Risk risk={fund.risk} />{' '}
                                                    </Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table>
                                    </FieldWrapper>
                                    <FormNavigations isFormValid={isValid} showBackButton={false} />
                                </Form>
                            )}
                            {isError && <Error error={validationContent.validation.dataLoadError} />}
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
}
