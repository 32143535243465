import CustomSelect from './CustomSelect';
import content from './content.json';

export default function SelectInput(props) {
    const {
        customField: Component,
        name,
        options = null,
        defaultValue = { label: content.defaultValue, value: 0 },
        children,
        onChange,
        ...rest
    } = props;

    const customOptions = options
        ? options
        : children.map(({ props, type }) => {
              if (type === 'option') {
                  return {
                      value: props.value,
                      label: props.children,
                  };
              }
              return null;
          });

    return (
        <Component name={name} {...rest}>
            {({ field, form }) => {
                return (
                    <CustomSelect
                        defaultValue={defaultValue}
                        options={customOptions}
                        name={field.name}
                        value={customOptions ? customOptions.find((option) => option.value === field.value) : ''}
                        onChange={onChange ? onChange : (option) => form.setFieldValue(field.name, option.value)}
                        onBlur={() => form.setFieldTouched(field.name, true)}
                        className='custom-select'
                        classNamePrefix='custom-select'
                    />
                );
            }}
        </Component>
    );
}
