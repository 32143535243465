import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { RadioBox } from '../../../../common/RadioBox/RadioBox';
import Error from '../../../../common/Error/Error';
import { Table } from '../../../../common/Table/Table';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import { formValues, saveFormValues, saveFundsData } from '../../../../../redux/slices/superBasketFormSlice';
import { useGetFirstStepDataQuery } from '../../../../../redux/services/superBasketFormApi';
import { changeSuperBasketStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { validationChooseWallet } from './validation';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import content from '../../../validation.json';
import styles from './SuperBasketChooseWalletForm.module.scss';

export default function SuperBasketChooseWalletForm(props) {
    const { step, setLoading } = props;
    const initValues = useSelector(formValues);
    const { data, isLoading: isDataLoading, isSuccess, isError } = useGetFirstStepDataQuery();
    const dispatch = useDispatch();

    const onFormSubmit = (values) => {
        dispatch(
            saveFormValues({
                ...values,
            })
        );

        const chosenWallet = data.wallets.filter((w) => w.id === +values.walletId)[0];

        dispatch(saveFundsData({ fundsData: data, chosenWallet: chosenWallet }));
        dispatch(changeSuperBasketStep(1));
    };

    useEffect(() => {
        if (isDataLoading) {
            setLoading(true);
        } else if (isSuccess || isError) {
            setLoading(false);
            responsiveIFrame();
        }
    }, [isDataLoading, isSuccess, isError]);

    return (
        <FormContainer customClass="SuperBasketFirstStep" title='Wybierz portfel'>
            <Formik enableReinitialize initialValues={initValues} validationSchema={validationChooseWallet} onSubmit={onFormSubmit}>
                {({ errors, values, isValid }) => {
                    return (
                        <>
                            {data && (
                                <Form>
                                    <FieldWrapper error={errors.walletId}>
                                        <Table responsive>
                                            <Table.Tr head>
                                                <Table.Td>Nazwa portfela</Table.Td>
                                                <Table.Td alignCenter colspan='3'>
                                                    Proporcje podziału jednostek uczestnictwa podlegających konwersji/zmianie do Funduszy/Subfunduszy
                                                    Docelowych w %
                                                </Table.Td>
                                            </Table.Tr>

                                            {data.wallets.map((wallet, index) => (
                                                <Table.Tr key={wallet.name}>
                                                    <Table.Td>
                                                        <RadioBox
                                                            id={`walletId_${index}`}
                                                            Field={
                                                                <Field
                                                                    type='radio'
                                                                    id={`walletId_${index}`}
                                                                    name='walletId'
                                                                    value={wallet.id}
                                                                    checked={+values.walletId === wallet.id}
                                                                />
                                                            }
                                                        >
                                                            <div className={styles.walletLabel}>{wallet.name}</div>
                                                        </RadioBox>
                                                    </Table.Td>
                                                    {wallet.assets.map((asset) => (
                                                        <Table.Td alignCenter>
                                                            <div>{asset.name}</div>
                                                            <div className={styles.proportionNumber}>{asset.proportion * 100}%</div>
                                                        </Table.Td>
                                                    ))}
                                                </Table.Tr>
                                            ))}
                                        </Table>
                                    </FieldWrapper>
                                    <FormNavigations isFormValid={isValid} showBackButton={false} />
                                </Form>
                            )}
                            {isError && <Error error={content.validation.dataLoadError} />}
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
}
