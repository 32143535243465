import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: {
        walletId: '',
    },
    fundsData: null,
    chosenWallet: null,
};

const superBasketFormSlice = createSlice({
    name: 'superBasketForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => ({
            ...state,
            formValues: {
                ...state.formValues,
                ...payload,
            },
        }),
        saveFundsData: (state, { payload }) => {
            state.fundsData = payload.fundsData;
            state.chosenWallet = payload.chosenWallet;
        },
    },
});

// selectors
export const formValues = (state) => state.superBasketForm.formValues;
export const fundsData = (state) => state.superBasketForm.fundsData;
export const chosenWallet = (state) => state.superBasketForm.chosenWallet;

// actions
export const { saveFormValues, saveFundsData } = superBasketFormSlice.actions;

// reducer
export default superBasketFormSlice.reducer;