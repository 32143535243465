export const formatNumber = (num, toFixedNum = 2) => {
    return Number((Math.round(+num * 10 ** toFixedNum) / 10 ** toFixedNum).toFixed(toFixedNum))
        .toLocaleString('pl-PL')
        .replaceAll(' ', ' ');
};

export const formatNumberWithoutConversion = (num, toFixedNum = 2) => {
    return Number((Math.round(+num * 10 ** toFixedNum) / 10 ** toFixedNum).toFixed(toFixedNum));
};

export const floorNumber = (num, toFixedNum = 0) => {
    return Number((Math.floor(+num * 10 **toFixedNum) / 10 ** toFixedNum).toFixed(toFixedNum))
    .toLocaleString('pl-PL')
    .replaceAll(' ', ' ');
};

export const floorNumberWithoutConversion = (num, toFixedNum = 2) => {
    return Number((Math.floor(+num * 10 ** toFixedNum) / 10 ** toFixedNum).toFixed(toFixedNum));
};