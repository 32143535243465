import React from 'react';
import clsx from 'clsx';

import styles from './StepItem.module.scss';

export const MobileStepItem = (props) => {
    const { item, currentStep, data, stepIndex } = props;

    return (
        <>
            <li className={clsx(styles.stepItem, styles['stepItem--passed'])}>
                <div>
                    <div className={styles.stepNumber}>
                        KROK {stepIndex + 1} z {data.length}
                    </div>
                    <div className={styles.stepName}>{item.name}</div>
                </div>
                {stepIndex < data.length - 1 && (
                    <div className={styles.mobileNext}>
                        <b>Następny krok:</b> {data[stepIndex + 1].name}
                    </div>
                )}
            </li>
        </>
    );
};
