export const lazyPdfMake = async () => {
    let pdfMake = await import('pdfmake/build/pdfmake');
    pdfMake = pdfMake.default;
    const pdfFonts = await import('../assets/fonts/SofiaPro/vfs_fonts.js');

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        SofiaPro: {
            normal: 'SofiaPro-Regular.otf',
            bold: 'SofiaPro-Bold.otf',
            light: 'SofiaPro-Light.otf',
            medium: 'SofiaPro-Medium.otf',
        },
    };
    return pdfMake;
};

export const lazyHtmlToPdfMake = async () => {
    let htmlToPdfmake = await import('html-to-pdfmake');
    htmlToPdfmake = htmlToPdfmake.default;
    return htmlToPdfmake;
};
