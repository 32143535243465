import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import SliderInput from '../../../../common/SliderInput/SliderInput';
import FieldInput from '../../../../common/FieldInput/FieldInput';
import { Table } from '../../../../common/Table/Table';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import SelectInput from '../../../../common/SelectInput/SelectInput';
import Calendar from '../../../../common/Calendar/Calendar';
import { chosenWallet as wallet } from '../../../../../redux/slices/superBasketFormSlice';
import { formValues, saveFormValues, saveResults } from '../../../../../redux/slices/superBasketWalletParametersFormSlice';
import { validationSuperBasketWalletParameters } from './validation';
import { tabletBreakpoint, phoneBreakpoint } from '../../../../../helpers/responsiveBreakpoints';
import { changeSuperBasketStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { useGetCalculatorResultsMutation } from '../../../../../redux/services/superBasketFormApi';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import content from '../../content.json';
import validationContent from '../../../validation.json';
import styles from './SuperBasketWalletParametersForm.module.scss';

export default function SuperBasketWalletParametersForm(props) {
    const { step, setLoading } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const isTabletMode = useMediaQuery({ maxWidth: tabletBreakpoint });
    const initValues = useSelector(formValues);
    const dispatch = useDispatch();
    const chosenWallet = useSelector(wallet);
    const [globalError, setGlobalError] = useState();

    const [getResults] = useGetCalculatorResultsMutation();

    useEffect(() => {
        responsiveIFrame();
    }, [])

    const onFormSubmit = async (values) => {
        setLoading(true);
        setGlobalError('');

        dispatch(
            saveFormValues({
                ...values,
            })
        );

        const body = {
            investmentAmount: values.investmentAmount,
            investmentPeriod: values.investmentPeriod,
            investmentStartDate: values.investmentStartDate,
            walletId: chosenWallet.id,
        };

        const response = await getResults(body);

        setLoading(false);

        if (response.error !== undefined) {
            if (response.error.status === 400) setGlobalError('Wprowadź poprawną datę.');
            else setGlobalError(validationContent.validation.dataLoadError);
        } else {
            setGlobalError('');
            dispatch(saveResults(response.data));
            dispatch(changeSuperBasketStep(2));
        }
    };

    return (
        <FormContainer title='Podaj parametry funduszy'>
            <Formik
                enableReinitialize
                initialValues={{ ...initValues }}
                validationSchema={validationSuperBasketWalletParameters}
                onSubmit={onFormSubmit}
            >
                {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                        {!isPhoneMode && !isTabletMode ? (
                            <Table>
                                <Table.Tr head>
                                    <Table.Td width='25%'>Okres</Table.Td>
                                    <Table.Td width='25%' alignCenter>
                                        Data początku inwestycji
                                    </Table.Td>
                                    <Table.Td alignCenter>Kwota jednorazowej inwestycji</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>
                                        <FieldWrapper
                                            error={touched.investmentPeriod && errors.investmentPeriod}
                                            errorTooltip
                                            tooltipPosition='bottom'
                                        >
                                            <SelectInput name='investmentPeriod' customField={Field}>
                                                {content.investmentPeriods.map((option) => {
                                                    return (
                                                        <option value={option.value} key={option.value}>
                                                            {option.label}
                                                        </option>
                                                    );
                                                })}
                                            </SelectInput>
                                        </FieldWrapper>
                                    </Table.Td>
                                    <Table.Td alignCenter>
                                        <FieldWrapper
                                            error={touched.investmentStartDate && errors.investmentStartDate}
                                            errorTooltip
                                            tooltipPosition='bottom'
                                        >
                                            <Calendar
                                                disableWeekends={true}
                                                value={values.investmentStartDate}
                                                onChange={(date) => {
                                                    let startDate = '';
                                                    if (date.length !== 0) {
                                                        const offset = date[0].getTimezoneOffset();
                                                        startDate = new Date(date[0].getTime() - offset * 60000).toISOString().split('T')[0];
                                                    }
                                                    setFieldValue('investmentStartDate', startDate);
                                                }}
                                                preText='od'
                                            />
                                        </FieldWrapper>
                                    </Table.Td>
                                    <Table.Td alignCenter>
                                        <div className={styles.sliderInputContainer}>
                                            <div className={styles.sliderContainer}>
                                                <SliderInput name='investmentAmount' min={5000} max={100000} step={300} customField={Field} />
                                            </div>
                                            <div className={styles.numberContainer}>
                                                <FieldWrapper
                                                    error={touched.investmentAmount && errors.investmentAmount}
                                                    errorTooltip
                                                    tooltipPosition='bottom'
                                                >
                                                    <FieldInput
                                                        type='number'
                                                        name='investmentAmount'
                                                        min={5000}
                                                        max={100000}
                                                        step={100}
                                                        customField={Field}
                                                    />
                                                </FieldWrapper>
                                            </div>
                                            <div className={styles.inputCurrency}>{content.investmentAmountCurrency}</div>
                                        </div>
                                    </Table.Td>
                                </Table.Tr>
                            </Table>
                        ) : (
                            <Table>
                                <Table.Tr head>
                                    <Table.Td>Okres</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>
                                        <FieldWrapper
                                            error={touched.investmentPeriod && errors.investmentPeriod}
                                            errorTooltip
                                            tooltipPosition='bottom'
                                        >
                                            <SelectInput name='investmentPeriod' customField={Field}>
                                                {content.investmentPeriods.map((option) => {
                                                    return (
                                                        <option value={option.value} key={option.value}>
                                                            {option.label}
                                                        </option>
                                                    );
                                                })}
                                            </SelectInput>
                                        </FieldWrapper>
                                    </Table.Td>
                                </Table.Tr>
                                <Table.Tr head>
                                    <Table.Td>Data początku inwestycji</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td>
                                        <FieldWrapper
                                            error={touched.investmentStartDate && errors.investmentStartDate}
                                            errorTooltip
                                            tooltipPosition='bottom'
                                        >
                                            <Calendar
                                                disableWeekends={true}
                                                value={values.investmentStartDate}
                                                onChange={(date) => {
                                                    let startDate = '';
                                                    if (date.length !== 0) {
                                                        const offset = date[0].getTimezoneOffset();
                                                        startDate = new Date(date[0].getTime() - offset * 60000).toISOString().split('T')[0];
                                                    }
                                                    setFieldValue('investmentStartDate', startDate);
                                                }}
                                                preText='od'
                                            />
                                        </FieldWrapper>
                                    </Table.Td>
                                </Table.Tr>
                                <Table.Tr head>
                                    <Table.Td>Kwota jednorazowej inwestycji (w PLN)</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td alignCenter>
                                        <div className={styles.sliderInputContainer}>
                                            <div className={styles.sliderContainer}>
                                                <SliderInput name='investmentAmount' min={5000} max={100000} step={300} customField={Field} />
                                            </div>
                                            <div className={styles.numberContainer}>
                                                <FieldWrapper
                                                    error={touched.investmentAmount && errors.investmentAmount}
                                                    errorTooltip
                                                    tooltipPosition='bottom'
                                                >
                                                    <FieldInput
                                                        type='number'
                                                        name='investmentAmount'
                                                        min={5000}
                                                        max={100000}
                                                        step={100}
                                                        customField={Field}
                                                    />
                                                </FieldWrapper>
                                            </div>
                                            <div className={styles.inputCurrency}>{content.investmentAmountCurrency}</div>
                                        </div>
                                    </Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                        <FormNavigations
                            showBackButton={true}
                            onBack={() => {
                                dispatch(changeSuperBasketStep(0));
                            }}
                            error={globalError}
                        />
                    </Form>
                )}
            </Formik>
        </FormContainer>
    );
}
