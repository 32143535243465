import React from 'react';
import clsx from 'clsx';

import styles from './GlobalLoader.module.scss';

export default function GlobalLoader(props) {
    const { isLoading } = props;

    return (
        <div className={clsx(styles.cLoader, !isLoading && styles['cLoader--hidden'])}>
            <div className={styles.cLoader__Spin}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
