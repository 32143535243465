import clsx from 'clsx';
import React from 'react';
import Error from '../Error/Error';

import ErrorTooltip from '../Error/ErrorTooltip';
import styles from './FieldWrapper.module.scss';

export default function FieldWrapper(props) {
    const { children, error, errorTooltip, tooltipPosition } = props;
    return (
        <div className={clsx(errorTooltip && styles.tooltipContainer)}>
            {children}
            {errorTooltip ? <ErrorTooltip error={error} position={tooltipPosition} /> : <Error error={error} />}
        </div>
    );
}
