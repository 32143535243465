import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Steps } from '../../../components/common/Steps/Steps';
import ChooseWalletForm from './steps/ChooseWalletForm/ChooseWalletForm';
import WalletParametersForm from './steps/WalletParametersForm/WalletParametersForm';
import ResultsAnalysis from './steps/ResultsAnalysis/ResultsAnalysis';
import { structure } from '../../../stepsStructure';
import { myPerspectiveCurrentStep, changeMyPerspectiveStep } from '../../../redux/slices/calculatorsStepsSlice';
import GlobalLoader from '../../common/GlobalLoader/GlobalLoader';
import styles from './MyPerspectiveForm.module.scss';

export const MyPerspectiveForm = () => {
    const step = useSelector(myPerspectiveCurrentStep);
    const [isLoading, setLoading] = useState(false);

    return (
        <div className={styles.formContainer}>
            <GlobalLoader isLoading={isLoading} />
            <div className={styles.calcHeader}>Kalkulator Moja Perspektywa</div>
            <Steps structure={structure.myPerspective} step={step} />
            {step === 0 && <ChooseWalletForm step={step} isLoading={isLoading} setLoading={setLoading}/>}
            {step === 1 && <WalletParametersForm step={step} is={isLoading} setLoading={setLoading}/>}
            {step === 2 && <ResultsAnalysis step={step} is={isLoading} setLoading={setLoading}/>}
        </div>
    );
};
