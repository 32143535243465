import React from 'react';
import 'rc-slider/assets/index.css';
import Slider, { Handle, SliderTooltip } from 'rc-slider';

import { ReactComponent as Arrows } from '../../../assets/img/svg/ico-range.svg';
import styles from './CustomSlider.module.scss';

export const CustomSlider = (props) => {
    const { min, max, markText, ...rest } = props;

    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <SliderTooltip
                defaultVisible={true}
                visible={true}
                prefixCls={styles.sliderTooltip}
                overlay={`${value} ${markText}`}
                placement='top'
                key={index}
            >
                <Handle value={value} {...restProps}>
                    <Arrows />
                </Handle>
            </SliderTooltip>
        );
    };

    const markStyle = {
        fontWeight: '400',
        fontSize: '1.4rem',
        top: '5px',
        width: '100%',
    };

    const marks = {
        [min]: {
            style: { ...markStyle },
            label: (
                <span>
                    {min} {markText}
                </span>
            ),
        },
        [max]: {
            style: { ...markStyle },
            label: (
                <span>
                    {max} {markText}
                </span>
            ),
        },
    };
    
    return (
        <Slider
            min={min}
            max={max}
            marks={marks}
            trackStyle={{ backgroundColor: '#d41817' }}
            handleStyle={{
                height: 25,
                width: 25,
                marginLeft: 0,
                marginTop: -9,
                border: 'none',
                borderRadius: '100%',
                boxShadow: 'none !important',
                borderColor: '#d41817',
                backgroundColor: '#d41817',
            }}
            tipProps={{ visible: true }}
            handle={handle}
            {...rest}
        />
    );
};
