import React, { useEffect } from 'react';
import styles from '../IFrameParent.module.scss';

export default function MyPerspectiveIFrameParent() {
    useEffect(() => {
        let eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        let eventer = window[eventMethod];
        let messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

        const onResizeHandler = function (e) {
            if (typeof e.data === 'string' && e.data.indexOf('documentHeight:') > -1) {
                let height = e.data.split('documentHeight:')[1];
                height = parseInt(height) + 100;
                document.getElementById('calculatorIFrame').height = height + 'px';
            }
        };
        eventer(messageEvent, onResizeHandler, false);

        return () => {
            window.removeEventListener(eventMethod === 'attachEvent' ? 'onmessage' : 'message', onResizeHandler);
        };
    }, []);

    return (
        <div className={styles.mainContainer}>
            <iframe
                src='https://front-edoradca.pekaotfi.pl/my-wallet-calc'
                id='calculatorIFrame'
                className={styles.calculatorIFrame}
                title='test'
                style={{ width: '100%' }}
            ></iframe>
        </div>
    );
}
