import React, { useEffect, useState, useRef, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';

import { AsyncLineChart } from '../../../../common/Chart/AsyncLineChart';
import { AsyncPieChart } from '../../../../common/Chart/AsyncPieChart';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import { Table } from '../../../../common/Table/Table';
import LegalInfoBox from '../../../../common/LegalInfoBox/LegalInfoBox';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import SectionContainer from '../../../../common/SectionContainer/SectionContainer';
import { formValues, results } from '../../../../../redux/slices/myPerspectiveWalletParametersFormSlice';
import { chosenWallet as wallet, fundsData as funds } from '../../../../../redux/slices/myPerspectiveFormSlice';
import { changeMyPerspectiveStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { lazyHtmlToPdfMake, lazyPdfMake } from '../../../../../helpers/lazyPdfMake';
import { phoneBreakpoint } from '../../../../../helpers/responsiveBreakpoints';
import { formatNumber } from '../../../../../helpers/formatNumber';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import arrowLeft from '../../../../../assets/img/svg/chart-btn-left.svg';
import arrowRight from '../../../../../assets/img/svg/chart-btn-right.svg';
import content from '../../content.json';
import styles from './ResultsAnalysis.module.scss';

export default function ResultsAnalysis(props) {
    const { step, isLoading, setLoading } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const dispatch = useDispatch();
    const chosenWallet = useSelector(wallet);
    const walletParameters = useSelector(formValues);
    const fundsData = useSelector(funds);
    const [rowsCollapsed, setRowsCollapsed] = useState(true);
    const data = useSelector(results);
    let pdfMake;
    let htmlToPdfmake;

    const [lineChartOptions, setLineChartOptions] = useState({
        exporting: {
            allowHTML: true,
            type: 'image/svg+xml',
            sourceWidth: 853,
            sourceHeight: 400,
            chartOptions: {
                legend: {
                    itemStyle: {
                        fontSize: isPhoneMode ? '14px' : '18px',
                    },
                },
            },
        },
        chart: {
            style: {
                fontFamily: 'Sofia Pro',
            },
            type: 'line',
            backgroundColor: '#fff',
            plotBackgroundColor: '#f9fafb',
            reflow: true,
            marginRight: isPhoneMode ? 0 : 20,
            height: 400,
        },
        title: {
            text: '',
        },
        legend: {
            enabled: true.valueOf,
            align: 'center',
            verticalAlign: 'bottom',
            x: 0,
            y: 0,
            itemStyle: {
                fontSize: isPhoneMode ? '14px' : '16px',
                textOverflow: 'clip',
            },
        },
        credits: {
            enabled: false,
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        scrollbar: {
            enabled: false,
        },
        navigator: {
            handles: {
                symbols: [`url(${arrowLeft})`, `url(${arrowRight})`],
            },
            xAxis: {
                labels: {
                    formatter: function () {
                        return this.value + 1;
                    },
                },
                showFirstLabel: true,
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        fontSize: '11px',
                    },
                },
            },
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: {
            labels: {
                formatter: function () {
                    return this.value + 1;
                },
                style: {
                    fontSize: '12px',
                },
            },
            showFirstLabel: true,
            showLastLabel: true,
            gridLineWidth: 1,
        },
        yAxis: {
            opposite: false,
            labels: {
                formatter: function () {
                    return formatNumber(this.value);
                },
                style: {
                    fontSize: '12px',
                },
            },
            title: {
                text: '',
            },
        },
        tooltip: {
            shared: true,
            useHTML: true,
            outside: false,
            style: {
                fontSize: '11px',
            },
            formatter(e) {
                let year = +this.points[0].x + 1 + ' rok inwestycji<br/>';
                let points = this.points.map(function (point) {
                    let marker = '';

                    if (point.series.symbol) {
                        switch (point.series.symbol) {
                            case 'circle':
                                marker = '●';
                                break;
                            case 'diamond':
                                marker = '♦';
                                break;
                            case 'square':
                                marker = '■';
                                break;
                            case 'triangle':
                                marker = '▲';
                                break;
                            case 'triangle-down':
                                marker = '▼';
                                break;
                            default:
                                break;
                        }
                    }

                    return (
                        '<span style="color: ' +
                        point.series.color +
                        '">' +
                        marker +
                        '</span> ' +
                        point.series.name +
                        ': <b>' +
                        formatNumber(point.y) +
                        ' PLN</b>'
                    );
                });

                setPieChartYear(this.points[0].x);

                return year + points.join('<br/>');
            },
        },
        colors: ['#053f6f', '#464646', '#c3c3c3', '#d41817'],
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                showInNavigator: true,
            },
        },
    });
    const [pieChartYear, setPieChartYear] = useState(1);
    const [pieChartOptions, setPieChartOptions] = useState({
        exporting: {
            allowHTML: true,
            type: 'image/svg+xml',
            sourceWidth: 600,
            sourceHeight: 400,
            chartOptions: {
                title: {
                    text: 'procentowy udział funduszy/subfunduszy',
                    style: {
                        fontSize: isPhoneMode ? '14px' : '25px'
                    },
                },
                legend: {
                    itemStyle: {
                        fontSize: '16px',
                    },
                    textOverflow: 'none',
                },
                series: {
                    data: data &&
                        data.yearlyResults && [
                            [chosenWallet.assets[0].name, Math.round(+data.yearlyResults[data.yearlyResults.length - 1].valuations[0] * 100) / 100],
                            [chosenWallet.assets[1].name, Math.round(+data.yearlyResults[data.yearlyResults.length - 1].valuations[1] * 100) / 100],
                            [chosenWallet.assets[2].name, Math.round(+data.yearlyResults[data.yearlyResults.length - 1].valuations[2] * 100) / 100],
                        ],
                },
            },
        },
        chart: {
            type: 'pie',
            style: {
                fontFamily: 'Sofia Pro',
            },
            backgroundColor: 'transparent',
            reflow: true,
            margin: [30, 0, 50, 0],
            height: isPhoneMode ? 300 : 400,
        },
        title: {
            text: `procentowy udział funduszy/subfunduszy w wypracowanej kwocie dla ${pieChartYear + 1} roku inwestycji`,
            style: {
                fontSize: isPhoneMode ? "14px": "16px",
            },
        },
        credits: {
            enabled: false,
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        legend: {
            itemStyle: {
                fontSize:  isPhoneMode ? '14px' : '16px',
            },
        },
        yAxis: {
            title: {
                text: 'Total percent market share',
            },
        },
        plotOptions: {
            pie: {
                shadow: false,
                borderWidth: 0,
            },
        },
        tooltip: {
            formatter: function () {
                return '<b>' + this.point.name + '</b>: ' + formatNumber(this.y) + ' PLN';
            },
        },
        series: [
            {
                data: [
                    [chosenWallet.assets[0].name, 6],
                    [chosenWallet.assets[1].name, 4],
                    [chosenWallet.assets[2].name, 7],
                ],
                size: '70%',
                innerSize: '70%',
                showInLegend: true,
                dataLabels: {
                    enabled: false,
                },
            },
        ],
        animation: true,
    });
    const lineChartRef = React.createRef(null);
    const pieChartRef = React.createRef(null);

    const rowStructure = (yearData, index, defaultCase) => {
        return defaultCase ? (
            <Table.Tr key={yearData.profitLoss + index}>
                <Table.Td>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
                <Table.Td alignCenter>...</Table.Td>
            </Table.Tr>
        ) : (
            <Table.Tr key={yearData.profitLoss + index}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.payments[0])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.valuations[0])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.payments[1])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.valuations[1])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.payments[2])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.valuations[2])}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.totalIncome)}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.profitLoss)}</Table.Td>
                <Table.Td alignCenter>{formatNumber(yearData.totalInvestmentValue)}</Table.Td>
            </Table.Tr>
        );
    };

    const rowConstructor = (data) => {
        let maxRows = 12;
        let dataLength = data.length;

        if (!rowsCollapsed || dataLength < maxRows) {
            return data.map((yearData, index) => rowStructure(yearData, index));
        } else {
            return data.map((yearData, index) => {
                if (index < 7 || dataLength - index < 3) return rowStructure(yearData, index);
                else if (index === 7) return rowStructure(yearData, index, true);
                else return null;
            });
        }
    };

    useEffect(() => {
        if (data && chosenWallet) {
            let chartTotalIncome = {
                name: 'Wpłaty ogółem',
                data: [],
            };

            let chartProfitLoss = {
                name: 'Zysk',
                data: [],
            };

            let chartTotalInvestmentValue = {
                name: 'Wartość inwestycji',
                data: [],
            };

            data.yearlyResults.forEach((dataEntry, index) => {
                chartTotalIncome.data.push(dataEntry.totalIncome);
                chartProfitLoss.data.push(dataEntry.profitLoss);
                chartTotalInvestmentValue.data.push(dataEntry.totalInvestmentValue);
            });

            setLineChartOptions({
                ...lineChartOptions,
                series: [chartTotalIncome, chartProfitLoss, chartTotalInvestmentValue],
            });

            setPieChartYear(data.yearlyResults.length - 1);

            setTimeout(() => {
                //give some time for the async charts to load
                responsiveIFrame();
            }, 100);
        }
    }, [data, chosenWallet]);

    useEffect(() => {
        if (data && pieChartYear !== null && chosenWallet) {
            setPieChartOptions({
                ...pieChartOptions,
                series: [
                    {
                        data: [
                            [chosenWallet.assets[0].name, Math.round(+data.yearlyResults[pieChartYear].valuations[0] * 100) / 100],
                            [chosenWallet.assets[1].name, Math.round(+data.yearlyResults[pieChartYear].valuations[1] * 100) / 100],
                            [chosenWallet.assets[2].name, Math.round(+data.yearlyResults[pieChartYear].valuations[2] * 100) / 100],
                        ],
                        size: '70%',
                        innerSize: '70%',
                        showInLegend: true,
                        dataLabels: {
                            enabled: false,
                        },
                    },
                ],
                title: {
                    text: `procentowy udział funduszy/subfunduszy w wypracowanej kwocie dla ${pieChartYear + 1} roku inwestycji`,
                },
            });
        }
    }, [pieChartYear]);

    const exportDataToPdf = async () => {
        if (lineChartRef && pieChartRef) {
            pdfMake = await lazyPdfMake();
            htmlToPdfmake = await lazyHtmlToPdfMake();

            let sanitizedLegalInfo = DOMPurify.sanitize(data.legalInformation, { USE_PROFILES: { html: true } }).replaceAll('&nbsp;', '');
            let dd = {
                pageMargins: [25, 25, 25, 30],
                defaultStyle: {
                    font: 'SofiaPro',
                    fontSize: 7,
                },
                footer: function (currentPage, pageCount) {
                    return { text: 'Strona ' + currentPage.toString() + ' z ' + pageCount, alignment: 'center', style: 'footer' };
                },
                content: [
                    {
                        text: 'Analizuj wyniki Moja Perspektywa',
                        margin: [0, 0, 0, 20],
                        style: 'header',
                    },
                    {
                        columns: [
                            {
                                svg: lineChartRef.current.chart.getSVGForExport(),
                                width: 330,
                            },
                            {
                                svg: pieChartRef.current.chart.getSVGForExport(),
                                width: 220,
                            },
                        ],
                    },
                    {
                        text: 'Podsumowanie',
                        style: 'subHeader',
                    },
                    {
                        style: 'parametersTable',
                        table: {
                            widths: ['*', 'auto', '*', 'auto', '*', 'auto', '*', 'auto'],
                            body: [
                                [
                                    { text: 'Okres inwestycji', bold: true, style: 'parametersHeader' },
                                    { text: `${walletParameters.investmentPeriod} lat`, style: 'parametersText', noWrap: true },

                                    { text: 'Częstotliwość wpłat', bold: true, style: 'parametersHeader' },
                                    {
                                        text: content.paymentFrequencies.filter((p) => +p.value === walletParameters.paymentFrequency)[0].label,
                                        style: 'parametersText',
                                        noWrap: true,
                                    },

                                    { text: 'Wysokość wpłat', bold: true, style: 'parametersHeader' },
                                    { text: `${walletParameters.paymentAmount} PLN`, style: 'parametersText', noWrap: true },

                                    { text: 'Pierwsza wpłata', bold: true, style: 'parametersHeader' },
                                    { text: `${fundsData.firstPayment} PLN`, style: 'parametersText', noWrap: true },
                                ],
                            ],
                        },
                        layout: 'noBorders',
                    },
                    {
                        text: chosenWallet.name,
                        style: 'subHeader',
                    },
                    {
                        style: 'assetsTable',
                        table: {
                            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                            body: [
                                [
                                    { text: 'Liczba lat', bold: true, style: 'assetsHeaderLeft', border: [false, false, false] },
                                    {
                                        text: `Suma wpłat do ${chosenWallet.assets[0].name} (w PLN)`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: `Wartość inwestycji w ${chosenWallet.assets[0].name}`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: `Suma wpłat do ${chosenWallet.assets[1].name} (w PLN)`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: `Wartość inwestycji w ${chosenWallet.assets[1].name}`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: `Suma wpłat do ${chosenWallet.assets[2].name} (w PLN)`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: `Wartość inwestycji w ${chosenWallet.assets[2].name}`,
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    { text: 'Wpłaty ogółem (w PLN)', bold: true, style: 'assetsHeader', border: [false, false, false] },
                                    { text: 'Zyski straty (w PLN)', bold: true, style: 'assetsHeader', border: [false, false, false] },
                                    { text: 'Wartość inwestycji ogółem (w PLN)', bold: true, style: 'assetsHeader', border: [false, false, false] },
                                ],
                                ...data.yearlyResults.map((year, index) => {
                                    return [
                                        { text: index + 1, style: 'assetsTextLeft', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.payments[0]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.valuations[0]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.payments[1]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.valuations[1]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.payments[2]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.valuations[2]), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.totalIncome), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        { text: formatNumber(year.profitLoss), style: 'assetsText', border: [false, false, false], noWrap: true },
                                        {
                                            text: formatNumber(year.totalInvestmentValue),
                                            style: 'assetsText',
                                            border: [false, false, false],
                                            noWrap: true,
                                        },
                                    ];
                                }),
                            ],
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return rowIndex % 2 === 0 ? '#fafafa' : null;
                            },
                        },
                        pageBreak: 'after',
                    },
                    htmlToPdfmake(sanitizedLegalInfo),
                ],
                styles: {
                    header: {
                        fontSize: 14,
                    },
                    subHeader: {
                        fontSize: 10,
                        margin: [0, 30, 0, 15],
                    },
                    parametersTable: {
                        fontSize: 8,
                        fillColor: '#f5f6f7',
                    },
                    parametersHeader: {
                        margin: [7, 10, 0, 7],
                    },
                    parametersText: {
                        margin: [0, 10, 7, 7],
                    },
                    assetsTable: {
                        fontSize: 6,
                    },
                    assetsHeader: {
                        margin: [3, 7, 3, 7],
                        alignment: 'center',
                    },
                    assetsHeaderLeft: {
                        margin: [3, 7, 3, 7],
                    },
                    assetsText: {
                        margin: [3, 5, 3, 5],
                        alignment: 'center',
                    },
                    assetsTextLeft: {
                        margin: [5, 5, 5, 5],
                    },
                    footer: {
                        fontSize: 8,
                        margin: [0, 10, 0, 0],
                    },
                },
            };

            let date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/[^0-9]/g, '');
            pdfMake.createPdf(dd).download(`MojaPerspektywa_${date.slice(0, date.length - 3)}.pdf`);
        }
    };

    return (
        <FormContainer title='Analizuj wyniki'>
            {data && (
                <>
                    <div className={styles.chartsContainer}>
                        <div>
                            <AsyncLineChart options={lineChartOptions} ref={lineChartRef} constructorType={'stockChart'} />
                        </div>
                        <div>
                            <AsyncPieChart options={pieChartOptions} updateArgs={[true, true, true]} ref={pieChartRef} />
                        </div>
                    </div>
                    <SectionContainer title='Podsumowanie'>
                        {!isPhoneMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Okres inwestycji</Table.Td>
                                    <Table.Td>{walletParameters.investmentPeriod} lat</Table.Td>
                                    <Table.Td head>Częstotliwość wpłat</Table.Td>
                                    <Table.Td>
                                        {content.paymentFrequencies.filter((p) => +p.value === walletParameters.paymentFrequency)[0].label}
                                    </Table.Td>
                                    <Table.Td head>Wysokość wpłat</Table.Td>
                                    <Table.Td>{walletParameters.paymentAmount} PLN</Table.Td>
                                    <Table.Td head>Pierwsza wpłata</Table.Td>
                                    <Table.Td>{fundsData.firstPayment} PLN</Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                        {isPhoneMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Okres inwestycji</Table.Td>
                                    <Table.Td>{walletParameters.investmentPeriod} lat</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Częstotliwość wpłat</Table.Td>
                                    <Table.Td>
                                        {content.paymentFrequencies.filter((p) => +p.value === walletParameters.paymentFrequency)[0].label}
                                    </Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Wysokość wpłat</Table.Td>
                                    <Table.Td>{walletParameters.paymentAmount} PLN</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Pierwsza wpłata</Table.Td>
                                    <Table.Td>{fundsData.firstPayment} PLN</Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                    </SectionContainer>
                    <SectionContainer title={chosenWallet && chosenWallet.name}>
                        <Table responsive>
                            <Table.Tr head>
                                <Table.Td>Liczba lat</Table.Td>
                                <Table.Td alignCenter>Suma wpłat do {chosenWallet.assets[0].name} (w PLN)</Table.Td>
                                <Table.Td alignCenter>Wartość inwestycji w {chosenWallet.assets[0].name}</Table.Td>
                                <Table.Td alignCenter>Suma wpłat do {chosenWallet.assets[1].name} (w PLN)</Table.Td>
                                <Table.Td alignCenter>Wartość inwestycji w {chosenWallet.assets[1].name}</Table.Td>
                                <Table.Td alignCenter>Suma wpłat do {chosenWallet.assets[2].name} (w PLN)</Table.Td>
                                <Table.Td alignCenter>Wartość inwestycji w {chosenWallet.assets[2].name}</Table.Td>
                                <Table.Td alignCenter>Wpłaty ogółem (w PLN)</Table.Td>
                                <Table.Td alignCenter>Zyski/straty (w PLN)</Table.Td>
                                <Table.Td alignCenter>Wartość inwestycji ogółem (w PLN)</Table.Td>
                            </Table.Tr>
                            {rowConstructor(data.yearlyResults)}
                        </Table>
                        <div className={styles.collapseRowsContainer} onClick={() => setRowsCollapsed(!rowsCollapsed)}>
                            {rowsCollapsed && data.yearlyResults.length >= 12 && <span>Rozwiń</span>}
                            {!rowsCollapsed && data.yearlyResults.length >= 12 && <span>Zwiń</span>}
                        </div>
                    </SectionContainer>
                    <div className={styles.legalBoxContainer}>
                        <LegalInfoBox htmlData={data.legalInformation} responsiveIFrame={responsiveIFrame} />
                    </div>
                    <FormNavigations
                        disableSubmit={!data}
                        showBackButton={true}
                        backText='Poprzedni krok'
                        nextText='Zapisz PDF'
                        onBack={() => {
                            dispatch(changeMyPerspectiveStep(1));
                        }}
                        onNext={() => {
                            exportDataToPdf();
                        }}
                    />
                </>
            )}
        </FormContainer>
    );
}
