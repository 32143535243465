import { CustomSlider } from './CustomSlider';

export default function SliderInput(props) {
    const { customField: Component, name, min, max, step = 1, markText = '', children, ...rest } = props;

    return (
        <Component name={name} {...rest}>
            {({ field, form }) => {
                return (
                    <CustomSlider
                        value={field.value ? field.value : min}
                        name={field.name}
                        onChange={(val) => form.setFieldValue(field.name, val)}
                        min={min}
                        max={max}
                        step={step}
                        markText={markText}
                    />
                );
            }}
        </Component>
    );
}
