import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Steps } from '../../../components/common/Steps/Steps';
import SuperBasketChooseWalletForm from './steps/SuperBasketChooseWalletForm/SuperBasketChooseWalletForm';
import SuperBasketWalletParametersForm from './steps/SuperBasketWalletParametersForm/SuperBasketWalletParametersForm';
import SuperBasketResultsAnalysis from './steps/SuperBasketResultsAnalysis/SuperBasketResultsAnalysis';
import { structure } from '../../../stepsStructure';
import { superBasketCurrentStep, changeSuperBasketStep } from '../../../redux/slices/calculatorsStepsSlice';
import GlobalLoader from '../../common/GlobalLoader/GlobalLoader';
import styles from './SuperBasketForm.module.scss';

export const SuperBasketForm = () => {
    const step = useSelector(superBasketCurrentStep);
    const [isLoading, setLoading] = useState(false);

    return (
        <div className={styles.formContainer}>
            <GlobalLoader isLoading={isLoading} />
            <div className={styles.calcHeader}>Kalkulator Super Basket</div>
            <Steps structure={structure.superBasket} step={step} />
            {step === 0 && <SuperBasketChooseWalletForm step={step} isLoading={isLoading} setLoading={setLoading} />}
            {step === 1 && <SuperBasketWalletParametersForm step={step} is={isLoading} setLoading={setLoading} />}
            {step === 2 && <SuperBasketResultsAnalysis step={step} is={isLoading} setLoading={setLoading} />}
        </div>
    );
};
