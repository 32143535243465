import clsx from 'clsx';
import React from 'react';

import styles from './Button.module.scss';

export const Button = (props) => {
    const { view = 'primary', className, disabled, children, onClick, type = 'submit', ...rest } = props;

    const params = {
        className: clsx(styles.button, styles['button--' + view], className, disabled && styles['button--disabled']),
        onClick: onClick,
        type: type,
        ...rest,
    };

    return <button {...params}>{children}</button>;
};
