import Home from './pages/Home/Home';
import MyPerspectiveIFrameParent from './pages/MyPerspectiveIFrameParent/MyPerspectiveIFrameParent';
import SuperBasketIFrameParent from './pages/SuperBasketIFrameParent/SuperBasketIFrameParent';
import MyWalletIFrameParent from './pages/MyWalletIFrameParent/MyWalletIFrameParent';
import MyPerspective from './pages/MyPerspective/MyPerspective';
import SuperBasket from './pages/SuperBasket/SuperBasket';
import MyWallet from './pages/MyWallet/MyWallet';

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        isDefault: true,
    },
    {
        path: '/my-perspective-calc',
        name: 'Moja Perspektywa',
        component: MyPerspective,
    },
    {
        path: '/super-basket-calc',
        name: 'Super Basket',
        component: SuperBasket,
    },
    {
        path: '/my-wallet-calc',
        name: 'Mój portfel',
        component: MyWallet,
    },
    {
        path: '/my-perspective-calc-iframe',
        name: 'My perspective IFrame',
        component: MyPerspectiveIFrameParent,
    },
    {
        path: '/super-basket-calc-iframe',
        name: 'Super Basket IFrame',
        component: SuperBasketIFrameParent,
    },
    {
        path: '/my-wallet-calc-iframe',
        name: 'My Wallet IFrame',
        component: MyWalletIFrameParent,
    },
];
