import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Polish } from 'flatpickr/dist/l10n/pl';

import { ReactComponent as Grid } from '../../../assets/img/svg/ico-grid.svg';
import styles from './Calendar.module.scss';

const CustomInput = (props) => {
    return (
        <input
            {...props}
            ref={props.inputRef}
            className={styles.dateInput}
            value={props.value}
            id={props.id}
            name={props.name}
            disabled={props.disabled}
        />
    );
};

export default function Calendar(props) {
    const { preText = '', value, disabled = false, disableWeekends = false, disableDates = () => {}, onChange, id, name } = props;
    return (
        <div className={styles.calendarContainer}>
            <Flatpickr
                onChange={onChange}
                render={(props, ref) => {
                    return <CustomInput value={value} inputRef={ref} id={id} name={name} disabled={disabled} />;
                }}
                options={{
                    disable: disableWeekends
                        ? [
                              function (date) {
                                  //disable weekends
                                  return date.getDay() === 0 || date.getDay() === 6;
                              },
                              disableDates,
                          ]
                        : [disableDates],
                    locale: Polish,
                }}
            />
            <span className={styles.preText}>{preText}</span>
            <span className={styles.icon}>
                <Grid />
            </span>
        </div>
    );
}
