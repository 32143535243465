import clsx from 'clsx';
import React from 'react';

import styles from './Table.module.scss';

export const Table = (props) => {
    const { children, greyFirst, colorEveryTwo = false, responsive, className = '', ...rest } = props;
    return (
        <div className={clsx(styles.tableContainer, responsive && 'table-responsive', className)}>
            <table
                width='100%'
                {...rest}
                className={clsx(
                    styles.tableEl,
                    greyFirst && styles.greyFirst,
                    !greyFirst && !colorEveryTwo && styles.whiteFirst,
                    colorEveryTwo && styles.colorEveryTwo
                )}
            >
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};

Table.Tr = (props) => {
    const { children, head, className = '', ...rest } = props;

    return (
        <tr {...rest} className={clsx(styles.trEl, head && styles.trEl__head, !head && styles.trEl__row, className)}>
            {children}
        </tr>
    );
};

Table.Td = (props) => {
    const { children, alignCenter, head, className = '', ...rest } = props;

    return (
        <td {...rest} className={clsx(styles.tdEl, alignCenter && styles.centered, head && styles.tdEl__head, className)}>
            {children}
        </td>
    );
};
