import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import DOMPurify from 'dompurify';

import styles from './LegalInfoBox.module.scss';

export default function LegalInfoBox(props) {
    const { htmlData = '', responsiveIFrame = () => {} } = props;
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        responsiveIFrame();
    }, [collapsed]);

    return (
        <div className={styles.legalBoxContainer}>
               <div
                className={clsx(styles.htmlContainer, !collapsed && styles['htmlContainer--expanded'])}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(!collapsed ? htmlData : htmlData.substring(0,600)+ '...', { USE_PROFILES: { html: true } }) }}
                ></div>
            <div className={styles.expandLabel} onClick={() => {setCollapsed(!collapsed)}}>
                {htmlData && collapsed && 'Więcej'}
                {htmlData && !collapsed && 'Mniej'}
            </div>
        </div>
    );
}
