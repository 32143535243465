import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import calculatorsStepsReducer from '../slices/calculatorsStepsSlice';
import myPerspectiveFormReducer from '../slices/myPerspectiveFormSlice';
import myPerspectiveWalletParametersFormReducer from '../slices/myPerspectiveWalletParametersFormSlice';
import superBasketFormReducer from '../slices/superBasketFormSlice';
import superBasketWalletParametersFormReducer from '../slices/superBasketWalletParametersFormSlice';
import myWalletFormReducer from '../slices/myWalletFormSlice';
import myWalletParametersFormReducer from '../slices/myWalletParametersFormSlice';
import { myPerspectiveFormApi } from '../services/myPerspectiveFormApi';
import { superBasketFormApi } from '../services/superBasketFormApi';
import { myWalletFormApi } from '../services/myWalletFormApi';

export const store = configureStore({
    reducer: {
        calculatorsSteps: calculatorsStepsReducer,
        myPerspectiveForm: myPerspectiveFormReducer,
        myPerspectiveWalletParametersForm: myPerspectiveWalletParametersFormReducer,
        superBasketForm: superBasketFormReducer,
        superBasketWalletParametersForm: superBasketWalletParametersFormReducer,
        myWalletForm: myWalletFormReducer,
        myWalletParametersForm: myWalletParametersFormReducer,
        [myPerspectiveFormApi.reducerPath]: myPerspectiveFormApi.reducer,
        [superBasketFormApi.reducerPath]: superBasketFormApi.reducer,
        [myWalletFormApi.reducerPath]: myWalletFormApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(myPerspectiveFormApi.middleware),
});

setupListeners(store.dispatch);
