import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as Arrow } from '../../../assets/img/svg/ico-arrow.svg';
import { phoneBreakpoint, tabletBreakpoint } from '../../../helpers/responsiveBreakpoints';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Arrow />
        </components.DropdownIndicator>
    );
};

export default function CustomSelect(props) {
    const { styles = null, name, ...rest } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const isTabletMode = useMediaQuery({ maxWidth: tabletBreakpoint });

    let fontSize = 1.8;
    if (isPhoneMode || isTabletMode) fontSize = 1.4;

    const defaultStyles = {
        control: (styles, state) => ({
            ...styles,
            borderRadius: '2rem',
            borderColor: state.isFocused ? '#bbb' : '#e7edef',
            boxShadow: 'none',
            fontSize: `${fontSize}rem`,
            fontFamily: 'Sofia Pro',
            fontWeight: '400',
            textAlign: 'center',
            cursor: 'pointer',
            height: '40px',

            ':hover': {
                ...styles[':hover'],
                borderColor: state.isFocused ? '#bbb' : '#e7edef',
            },
        }),
        valueContainer: (styles) => ({
            ...styles,
            padding: '0 15px',
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            padding: '0 15px 0 10px',
        }),
        input: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0,
        }),
        menu: (styles) => ({
            ...styles,
            marginTop: 0,
            zIndex: 10
        }),
        option: (styles, state) => ({
            ...styles,
            zIndex: 10,
            fontSize: `${fontSize}rem`,
            fontFamily: 'Sofia Pro',
            textAlign: 'center',
            backgroundColor: state.isSelected && '#1e90ff',
            color: state.isSelected && '#ffffff',
            cursor: 'pointer',

            ':hover': {
                ...styles[':hover'],
                backgroundColor: '#1e90ff',
                color: '#ffffff'
            },
            ':active': {
                ...styles[':active'],
                backgroundColor: '#1e90ff',
                color: '#ffffff'
            },
        }),
    };

    return <Select name={name} id={name} components={{ DropdownIndicator }} isSearchable={false} styles={styles ? styles : defaultStyles} {...rest} />;
}
