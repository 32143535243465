import clsx from 'clsx';
import React from 'react';

import styles from './Risk.module.scss';

export default function Risk(props) {
    const { risk } = props;
    const riskMax = 7;
    return (
        <div className={styles.riskContainer}>
            {[...Array(riskMax)].map((_, index) => {
                return <span key={index + risk} className={clsx(styles.riskItem, index < risk && styles['riskItem--active'])}></span>;
            })}
        </div>
    );
}
