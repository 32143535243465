import React from 'react';

import styles from './SectionContainer.module.scss';

export default function SectionContainer(props) {
    const { children, title = '' } = props;
    return (
        <div className={styles.sectionContainer}>
            <div className={styles.sectionTitle}>{title}</div>
            {children}
        </div>
    );
}
