import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';

import { phoneBreakpoint, tabletBreakpoint } from '../../../../../helpers/responsiveBreakpoints';
import { formValues, results } from '../../../../../redux/slices/myWalletParametersFormSlice';
import { chosenFundsData as funds } from '../../../../../redux/slices/myWalletFormSlice';
import { floorNumber, formatNumber } from '../../../../../helpers/formatNumber';
import { AsyncLineChart } from '../../../../common/Chart/AsyncLineChart';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import SectionContainer from '../../../../common/SectionContainer/SectionContainer';
import SelectInput from '../../../../common/SelectInput/SelectInput';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import { changeMyWalletStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import LegalInfoBox from '../../../../common/LegalInfoBox/LegalInfoBox';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import { lazyHtmlToPdfMake, lazyPdfMake } from '../../../../../helpers/lazyPdfMake';
import { Table } from '../../../../common/Table/Table';
import arrowLeft from '../../../../../assets/img/svg/chart-btn-left.svg';
import arrowRight from '../../../../../assets/img/svg/chart-btn-right.svg';
import styles from './MyWalletResultsAnalysis.module.scss';

export default function MyWalletResultsAnalysis(props) {
    const { step, isLoading, setLoading } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const isTabletMode = useMediaQuery({ maxWidth: tabletBreakpoint });
    const dispatch = useDispatch();
    const [globalError, setGlobalError] = useState();
    const fundsData = useSelector(funds);
    const fundsParameters = useSelector(formValues);
    const data = useSelector(results);
    const formRef = useRef(null);
    let pdfMake;
    let htmlToPdfmake;

    const chartColors = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
    const areaChartRef = React.createRef(null);
    const [areaChartOptions, setAreaChartOptions] = useState({
        exporting: {
            allowHTML: true,
            type: 'image/svg+xml',
            sourceWidth: 1338,
            sourceHeight: 400,
        },
        chart: {
            style: {
                fontFamily: 'Sofia Pro',
            },
            type: 'area',
            backgroundColor: '#fff',
            plotBackgroundColor: '#f9fafb',
            reflow: true,
            height: 400,
        },
        title: {
            text: '',
        },
        legend: {
            layout: isTabletMode ? 'horizontal' : 'vertical',
            align: isTabletMode ? 'left' : 'right',
            verticalAlign: isTabletMode ? 'bottom' : 'top',
            enabled: true.valueOf,
            x: 0,
            y: 0,
            itemStyle: {
                fontSize: isTabletMode ? '14px' : '16px',
                textOverflow: 'clip',
                fontWeight: 'regular',
            },
            itemMarginBottom: 7,
            title: {
                text: 'Podział funduszy',
                style: {
                    fontSize: isTabletMode ? '14px' : '16px',
                },
            },
        },
        credits: {
            enabled: false,
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        scrollbar: {
            enabled: false,
        },
        navigator: {
            handles: {
                symbols: [`url(${arrowLeft})`, `url(${arrowRight})`],
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    day: '%d.%m.%y',
                },
                labels: {
                    style: {
                        fontSize: '12px',
                    },
                },
                showFirstLabel: true,
            },
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%d.%m.%y',
            },
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
            showFirstLabel: true,
        },
        yAxis: {
            opposite: false,
            labels: {
                formatter: function () {
                    return formatNumber(this.value);
                },
                style: {
                    fontSize: '12px',
                },
            },
            title: {
                text: '',
            },
        },
        tooltip: {
            shared: true,
            useHTML: true,
            outside: false,
            style: {
                fontSize: '11px',
            },
            formatter(e) {
                let date = 'Wartość inwestycji z dnia <b>' + new Date(this.points[0].x).toLocaleDateString('pl-PL') + '</b></br>';
                let points = this.points.map(function (point) {
                    let marker = '';

                    if (point.series.symbol) {
                        switch (point.series.symbol) {
                            case 'circle':
                                marker = '●';
                                break;
                            case 'diamond':
                                marker = '♦';
                                break;
                            case 'square':
                                marker = '■';
                                break;
                            case 'triangle':
                                marker = '▲';
                                break;
                            case 'triangle-down':
                                marker = '▼';
                                break;
                            default:
                                break;
                        }
                    }

                    return (
                        '<span style="color: ' +
                        point.series.color +
                        '">' +
                        marker +
                        '</span> ' +
                        point.series.name +
                        ': <b>' +
                        formatNumber(point.y) +
                        ' PLN</b>'
                    );
                });

                return date + points.join('<br/>');
            },
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                showInNavigator: true,
            },
        },
    });

    const onSelectCurrency = (option, name, setFieldValue) => {
        setFieldValue(name, option.value);
        let chartData = getChartData(option.value);
        setAreaChartOptions({
            ...areaChartOptions,
            series: [...chartData],
        });
    };

    useEffect(() => {
        if (data) {
            console.log(data.legalInformation);
            let chartData = getChartData(Object.keys(data.chart)[0]);
            setAreaChartOptions({
                ...areaChartOptions,
                series: [...chartData],
            });
            setTimeout(() => {
                //give some time for the async charts to load
                responsiveIFrame();
            }, 100);
        }
    }, [data]);

    const getChartData = (currency) => {
        let chartData = data.chart[currency];
        let chartFundsArray = Object.entries(chartData).map(([id, fundData], index) => {
            let fundDataArray = fundData.map((fund) => {
                return [+new Date(fund.priceDate), fund.priceValue];
            });

            return {
                name: `${fundData[0].name} (${Math.round(data.summary[id].percentage * 100)}%)`,
                data: fundDataArray.sort(function (x, y) {
                    return x[0] - y[0];
                }),
                color: chartColors[index],
            };
        });

        return chartFundsArray.sort(function (x, y) {
            return Math.max(...y.data.map((i) => i[1])) - Math.max(...x.data.map((i) => i[1]));
        });
    };

    const exportDataToPdf = async () => {
        if (areaChartRef && formRef) {
            pdfMake = await lazyPdfMake();
            htmlToPdfmake = await lazyHtmlToPdfMake();

            let sanitizedLegalInfo = DOMPurify.sanitize(data.legalInformation, { USE_PROFILES: { html: true } }).replaceAll('&nbsp;', '');
            let dd = {
                pageMargins: [25, 25, 25, 30],
                defaultStyle: {
                    font: 'SofiaPro',
                    fontSize: 7,
                },
                footer: function (currentPage, pageCount) {
                    return { text: 'Strona ' + currentPage.toString() + ' z ' + pageCount, alignment: 'center', style: 'footer' };
                },
                content: [
                    {
                        text: 'Analizuj wyniki Mój portfel',
                        style: 'header',
                    },
                    {
                        text: formRef.current.values.graphCurrency,
                        style: 'subHeader',
                    },
                    {
                        svg: areaChartRef.current.chart.getSVGForExport(),
                        width: 550,
                    },
                    {
                        text: 'Podsumowanie',
                        style: 'subHeader',
                    },
                    {
                        style: 'assetsTable',
                        table: {
                            widths: ['auto', 'auto', '*', '*', '*', '*', '*', '*', '*', '*'],
                            body: [
                                [
                                    {
                                        text: 'Nazwa funduszu / subfunduszu',
                                        bold: true,
                                        style: 'assetsHeaderLeft',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Kategoria jednostek',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Udział portfela %',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Data początku inwestycji',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Wartość j. u. z opłatą manipulacyjną w dniu zakupu',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Opłata manipulacyjna %',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Wartość j.u. na dzień zakończenia inwestycji',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Liczba nabytych j. u.',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Data zakończenia inwestycji',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: 'Zysk netto',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                ],
                                ...Object.entries(data.summary).map(([id, fund]) => {
                                    return [
                                        {
                                            text: fundsData.filter((fund) => fund.externalId === +id)[0].name,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: fundsData.filter((fund) => fund.externalId === +id)[0].class,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: `${Math.round(fund.percentage * 100)}%`,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: new Date(fund.startCalculationDate).toLocaleDateString('pl-PL'),
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: formatNumber(fund.startCalculationValueWithFee),
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: `${formatNumber(fundsParameters[+id].fee)}%`,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: `${formatNumber(fund.endCalculationValue)} ${fund.currency}`,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: floorNumber(fundsParameters[+id].units, 3),
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: new Date(fund.endCalculationDate).toLocaleDateString('pl-PL'),
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                        {
                                            text: `${formatNumber(fund.profit)} ${fund.currency}`,
                                            style: 'assetsText',
                                            border: [false, false, false],
                                        },
                                    ];
                                }),
                                [
                                    {
                                        text: 'Podsumowanie',
                                        bold: true,
                                        style: 'assetsHeaderLeft',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '100%',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        text: '',
                                        bold: true,
                                        style: 'assetsHeader',
                                        border: [false, false, false],
                                    },
                                    {
                                        style: 'assetsTable',
                                        table: {
                                            widths: ['*'],
                                            body: [
                                                ...Object.entries(data.profitSummery).map(([currency, profit]) => [
                                                    {
                                                        text: `${formatNumber(profit)} ${currency}`,
                                                        bold: true,
                                                        style: 'currencyItem',
                                                    },
                                                ]),
                                            ],
                                        },
                                        layout: 'noBorders',
                                        border: [false, false, false],
                                    },
                                ],
                            ],
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return rowIndex % 2 === 0 ? '#fafafa' : null;
                            },
                        },
                        pageBreak: 'after',
                    },
                    htmlToPdfmake(sanitizedLegalInfo),
                ],
                styles: {
                    header: {
                        fontSize: 14,
                    },
                    subHeader: {
                        fontSize: 10,
                        margin: [0, 15, 0, 15],
                    },
                    assetsTable: {
                        fontSize: 6,
                    },
                    assetsHeader: {
                        margin: [3, 7, 3, 7],
                        alignment: 'center',
                    },
                    assetsHeaderLeft: {
                        margin: [3, 7, 3, 7],
                    },
                    assetsText: {
                        margin: [3, 5, 3, 5],
                        alignment: 'center',
                    },
                    assetsTextLeft: {
                        margin: [5, 5, 5, 5],
                    },
                    currencyItem: {
                        margin: [3, 0, 0, 3],
                        alignment: 'center',
                    },
                    footer: {
                        fontSize: 8,
                        margin: [0, 10, 0, 0],
                    },
                },
            };

            let date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/[^0-9]/g, '');
            pdfMake.createPdf(dd).download(`MójPortfel_${date.slice(0, date.length - 3)}.pdf`);
        }
    };

    return (
        <FormContainer title='Analizuj wyniki'>
            {data && fundsParameters && (
                <>
                    <SectionContainer>
                        <div className={styles.chooseCurrencyContainer}>
                            <div>Wybierz walutę:</div>
                            <div className={styles.selectContainer}>
                                <Formik enableReinitialize initialValues={{ graphCurrency: Object.keys(data.chart)[0] }} innerRef={formRef}>
                                    {({ errors, values, touched, setFieldValue, isValid }) => {
                                        return (
                                            <SelectInput
                                                name='graphCurrency'
                                                customField={Field}
                                                onChange={(option) => onSelectCurrency(option, 'graphCurrency', setFieldValue)}
                                            >
                                                {Object.keys(data.chart).map((option, index) => {
                                                    return (
                                                        <option value={option} key={option + index}>
                                                            {option}
                                                        </option>
                                                    );
                                                })}
                                            </SelectInput>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                        <div>
                            <AsyncLineChart options={areaChartOptions} ref={areaChartRef} constructorType={'stockChart'} standardColors />
                        </div>
                    </SectionContainer>
                    <SectionContainer title='Podsumowanie'>
                        <Table responsive>
                            <Table.Tr head>
                                <Table.Td>Nazwa funduszu / subfunduszu</Table.Td>
                                <Table.Td alignCenter>Kategoria jednostek</Table.Td>
                                <Table.Td alignCenter>Udział portfela %</Table.Td>
                                <Table.Td alignCenter>Data początku inwestycji</Table.Td>
                                <Table.Td alignCenter>Wartość j. u. z opłatą manipulacyjną w dniu zakupu</Table.Td>
                                <Table.Td alignCenter>Opłata manipulacyjna %</Table.Td>
                                <Table.Td alignCenter>Wartość j.u. na dzień zakończenia inwestycji</Table.Td>
                                <Table.Td alignCenter>Liczba nabytych j.u.</Table.Td>
                                <Table.Td alignCenter>Data zakończenia inwestycji</Table.Td>
                                <Table.Td alignCenter>Zysk netto</Table.Td>
                            </Table.Tr>
                            {Object.entries(data.summary).map(([id, fund]) => (
                                <Table.Tr key={id}>
                                    <Table.Td>{fundsData.filter((fund) => fund.externalId === +id)[0].name}</Table.Td>
                                    <Table.Td alignCenter>{fundsData.filter((fund) => fund.externalId === +id)[0].class}</Table.Td>
                                    <Table.Td alignCenter>{Math.round(fund.percentage * 100)}%</Table.Td>
                                    <Table.Td alignCenter>{new Date(fund.startCalculationDate).toLocaleDateString('pl-PL')}</Table.Td>
                                    <Table.Td alignCenter>{formatNumber(fund.startCalculationValueWithFee)}</Table.Td>
                                    <Table.Td alignCenter>{formatNumber(fundsParameters[+id].fee)}%</Table.Td>
                                    <Table.Td alignCenter>
                                        {formatNumber(fund.endCalculationValue)} {fund.currency}
                                    </Table.Td>
                                    <Table.Td alignCenter>{floorNumber(fundsParameters[+id].units, 3)}</Table.Td>
                                    <Table.Td alignCenter>{new Date(fund.endCalculationDate).toLocaleDateString('pl-PL')}</Table.Td>
                                    <Table.Td alignCenter width='10%'>
                                        <div className={styles.profitContainer}>
                                            {formatNumber(fund.profit)} {fund.currency}
                                        </div>
                                    </Table.Td>
                                </Table.Tr>
                            ))}
                            <Table.Tr>
                                <Table.Td head>Podsumowanie</Table.Td>
                                <Table.Td></Table.Td>
                                <Table.Td head alignCenter>
                                    100%
                                </Table.Td>
                                <Table.Td colSpan='6'></Table.Td>
                                <Table.Td head alignCenter>
                                    {Object.entries(data.profitSummery).map(([currency, profit]) => (
                                        <div className={styles.profitItem}>
                                            {formatNumber(profit)} {currency}
                                        </div>
                                    ))}
                                </Table.Td>
                            </Table.Tr>
                        </Table>
                    </SectionContainer>
                    <div className={styles.legalBoxContainer}>
                        <LegalInfoBox htmlData={data.legalInformation} responsiveIFrame={responsiveIFrame} />
                    </div>
                    <FormNavigations
                        showBackButton={true}
                        onBack={() => {
                            dispatch(changeMyWalletStep(1));
                        }}
                        nextText='Zapisz PDF'
                        onNext={() => {
                            exportDataToPdf();
                        }}
                        error={globalError}
                    />
                </>
            )}
        </FormContainer>
    );
}
