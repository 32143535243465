
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';

import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import SectionContainer from '../../../../common/SectionContainer/SectionContainer';
import { Table } from '../../../../common/Table/Table';
import { floorNumber, formatNumber, formatNumberWithoutConversion } from '../../../../../helpers/formatNumber';
import LegalInfoBox from '../../../../common/LegalInfoBox/LegalInfoBox';
import Error from '../../../../common/Error/Error';
import { AsyncLineChart } from '../../../../common/Chart/AsyncLineChart';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import { formValues, results } from '../../../../../redux/slices/superBasketWalletParametersFormSlice';
import { chosenWallet as wallet } from '../../../../../redux/slices/superBasketFormSlice';
import { changeSuperBasketStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { phoneBreakpoint, tabletBreakpoint } from '../../../../../helpers/responsiveBreakpoints';
import { lazyHtmlToPdfMake, lazyPdfMake } from '../../../../../helpers/lazyPdfMake';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import arrowLeft from '../../../../../assets/img/svg/chart-btn-left.svg';
import arrowRight from '../../../../../assets/img/svg/chart-btn-right.svg';
import content from '../../content.json';
import styles from './SuperBasketResultsAnalysis.module.scss';

export default function SuperBasketResultsAnalysis(props) {
    const { step, isLoading, setLoading } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const isTabletMode = useMediaQuery({ maxWidth: tabletBreakpoint });
    const dispatch = useDispatch();
    const chosenWallet = useSelector(wallet);
    const walletParameters = useSelector(formValues);
    const data = useSelector(results);
    const [lastDayAssets, setLastDayAssets] = useState();
    let pdfMake;
    let htmlToPdfmake;

    const areaChartRef = React.createRef(null);
    const [areaChartOptions, setAreaChartOptions] = useState({
        exporting: {
            allowHTML: true,
            type: 'image/svg+xml',
            sourceWidth: 1338,
            sourceHeight: 400,
        },
        chart: {
            style: {
                fontFamily: 'Sofia Pro',
            },
            type: 'area',
            backgroundColor: '#fff',
            plotBackgroundColor: '#f9fafb',
            reflow: true,
            height: 400,
        },
        title: {
            text: '',
        },
        legend: {
            layout: isTabletMode ? 'horizontal' : 'vertical',
            align: isTabletMode ? 'left' : 'right',
            verticalAlign: isTabletMode ? 'bottom' : 'top',
            enabled: true.valueOf,
            x: 0,
            y: 0,
            itemStyle: {
                fontSize: isTabletMode ? '14px' : '16px',
                textOverflow: 'clip',
                fontWeight: 'regular',
            },
            itemMarginBottom: 7,
            title: {
                text: 'Podział wpłat',
                style: {
                    fontSize: isTabletMode ? '14px' : '16px',
                },
            },
        },
        credits: {
            enabled: false,
        },
        navigation: {
            buttonOptions: {
                enabled: false,
            },
        },
        scrollbar: {
            enabled: false,
        },
        navigator: {
            handles: {
                symbols: [`url(${arrowLeft})`, `url(${arrowRight})`],
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%d.%m.%y',
                },
                labels: {
                    style: {
                        fontSize: '12px',
                    },
                },
                showFirstLabel: true,
            },
        },
        rangeSelector: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                month: '%d.%m.%y',
            },
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
            showFirstLabel: true,
            gridLineWidth: 1,
        },
        yAxis: {
            opposite: false,
            labels: {
                formatter: function () {
                    return formatNumber(this.value);
                },
                style: {
                    fontSize: '12px',
                },
            },
            title: {
                text: '',
            },
        },
        tooltip: {
            shared: true,
            useHTML: true,
            outside: isTabletMode ? false : true,
            style: {
                fontSize: isPhoneMode ? '12px' : '14px',
            },
            shape: 'square',
            formatter(e) {
                let date = 'Wartość inwestycji z dnia <b>' + new Date(this.points[0].x).toLocaleDateString('pl-PL') + '</b>';
                let points = this.points.map(function (point) {
                    let marker = '';

                    if (point.series.symbol) {
                        switch (point.series.symbol) {
                            case 'circle':
                                marker = '●';
                                break;
                            case 'diamond':
                                marker = '♦';
                                break;
                            case 'square':
                                marker = '■';
                                break;
                            case 'triangle':
                                marker = '▲';
                                break;
                            case 'triangle-down':
                                marker = '▼';
                                break;
                            default:
                                break;
                        }
                    }
                    return (
                        '<tr>' +
                        '<td width="10%">' +
                        '<span style="color: ' +
                        point.series.color +
                        '">' +
                        marker +
                        '</span>' +
                        '</td>' +
                        '<td width="50%"><div>' +
                        point.series.name +
                        '</div></td>' +
                        '<td width="40%" style="text-align: right"><div>' +
                        '<b>' +
                        formatNumber(point.y) +
                        ' PLN</b>' +
                        '</div></td>' +
                        '</tr>'
                    );
                });

                return '<div>' + date + '<table><tbody>' + points.join('') + '</tbody></table></div>';
            },
            positioner: isTabletMode
                ? undefined
                : function () {
                      return {
                          x: this.chart.clipBox.width + 70,
                          y: this.chart.clipBox.height / 2,
                      };
                  },
        },
        series: [
            {
                data: [],
            },
        ],
        colors: ['#053f6f', '#464646', '#c3c3c3', '#d41817'],
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                showInNavigator: true,
            },
        },
    });

    useEffect(() => {
        if (data) {
            let chartsDataGeneral = [];
            let chartsDataGeneralFirstAsset = [];
            let chartsDataGeneralSecondAsset = [];
            let chartsDataGeneralThirdAsset = [];
            Object.entries(data.calculations.iteration).forEach(([key, value]) => {
                let date = +new Date(key);
                chartsDataGeneral.push([date, Object.values(value)[0]]);
                chartsDataGeneralFirstAsset.push([date, Object.values(value)[1]]);
                chartsDataGeneralSecondAsset.push([date, Object.values(value)[2]]);
                chartsDataGeneralThirdAsset.push([date, Object.values(value)[3]]);
            });
            let seriesItems = [
                { name: content.generalAssetName, data: chartsDataGeneral },
                { name: `${chosenWallet.assets[0].name} (${chosenWallet.assets[0].proportion * 100}%)`, data: chartsDataGeneralFirstAsset },
                { name: `${chosenWallet.assets[1].name} (${chosenWallet.assets[1].proportion * 100}%)`, data: chartsDataGeneralSecondAsset },
            ];
            if (chosenWallet.assets.length == 3) {
                seriesItems.push({
                    name: `${chosenWallet.assets[2].name} (${chosenWallet.assets[2].proportion * 100}%)`,
                    data: chartsDataGeneralThirdAsset,
                });
            }
            setAreaChartOptions({
                ...areaChartOptions,
                series: seriesItems,
            });

            setLastDayAssets(Object.values(Object.values(data.calculations.iteration)[Object.values(data.calculations.iteration).length - 1]));

            setTimeout(() => {
                //give some time for the async charts to load
                responsiveIFrame();
            }, 100);
        }
    }, [data]);

    const exportDataToPdf = async () => {
        if (areaChartRef) {
            pdfMake = await lazyPdfMake();
            htmlToPdfmake = await lazyHtmlToPdfMake();
            const multipleTableParams = () => {
                let multipleTable = [
                    { text: '', style: 'assetsHeaderLeft', border: [false, false, false] },
                    {
                        text: `${chosenWallet.assets[0].name} (${chosenWallet.assets[0].proportion * 100}%)`,
                        bold: true,
                        style: 'assetsHeader',
                        border: [false, false, false],
                    },
                    {
                        text: `${chosenWallet.assets[1].name} (${chosenWallet.assets[1].proportion * 100}%)`,
                        bold: true,
                        style: 'assetsHeader',
                        border: [false, false, false],
                    },
                ];
                if (chosenWallet.assets.length > 2) {
                    multipleTable.push({
                        text: `${chosenWallet.assets[2].name} (${chosenWallet.assets[2].proportion * 100}%)`,
                        bold: true,
                        style: 'assetsHeader',
                        border: [false, false, false],
                    });
                    return multipleTable;
                }

                return multipleTable;
            };
            let sanitizedLegalInfo = DOMPurify.sanitize(data.legalInformation, { USE_PROFILES: { html: true } }).replaceAll('&nbsp;', '');
            let dd = {
                pageMargins: [25, 25, 25, 30],
                defaultStyle: {
                    font: 'SofiaPro',
                    fontSize: 7,
                },
                footer: function (currentPage, pageCount) {
                    return { text: 'Strona ' + currentPage.toString() + ' z ' + pageCount, alignment: 'center', style: 'footer' };
                },
                content: [
                    {
                        text: 'Analizuj wyniki Super Basket',
                        style: 'header',
                    },
                    {
                        text: 'Podsumowanie',
                        style: 'subHeader',
                    },
                    {
                        style: 'parametersTable',
                        table: {
                            widths: ['auto', '*', 'auto', '*'],
                            body: [
                                [
                                    { text: 'Okres inwestycji', bold: true, style: 'parametersHeader' },
                                    {
                                        text: content.investmentPeriods.filter((p) => +p.value === walletParameters.investmentPeriod)[0].label,
                                        style: 'parametersText',
                                        noWrap: true,
                                    },
                                    { text: 'Data początku inwestycji', bold: true, style: 'parametersHeader' },
                                    {
                                        text: new Date(walletParameters.investmentStartDate).toLocaleDateString('pl-PL'),
                                        style: 'parametersText',
                                        noWrap: true,
                                    },
                                ],
                            ],
                        },
                        layout: 'noBorders',
                    },
                    {
                        text: chosenWallet.name,
                        style: 'subHeader',
                    },
                    {
                        style: 'assetsTable',
                        table: {
                            widths: chosenWallet.assets.length == 2 ? ['auto', '*', '*'] : ['auto', '*', '*', '*'],
                            body: [
                                multipleTableParams(),
                                [
                                    {
                                        text: `Wartość inwestycji na dzień ${new Date(
                                            Object.keys(data.calculations.iteration)[Object.keys(data.calculations.iteration).length - 1]
                                        ).toLocaleDateString('pl-PL')} (PLN)`,
                                        style: 'assetsTextLeft',
                                        border: [false, false, false],
                                    },
                                    ...lastDayAssets.slice(1).map((asset, index) => {
                                        return { text: formatNumber(asset), style: 'assetsText', border: [false, false, false], noWrap: true };
                                    }),
                                ],
                                [
                                    {
                                        text: 'Ilość nabytych jednostek w ramach Programu',
                                        style: 'assetsTextLeft',
                                        border: [false, false, false],
                                    },
                                    ...Object.values(data.calculations.totalUnits).map((asset) => ({
                                        text: formatNumber(asset),
                                        style: 'assetsText',
                                        border: [false, false, false],
                                        noWrap: true,
                                    })),
                                ],
                            ],
                        },

                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return rowIndex % 2 === 0 ? '#fafafa' : null;
                            },
                        },
                    },
                    {
                        text: 'Przebieg inwestycji Super Basket',
                        style: 'subHeader',
                    },
                    {
                        svg: areaChartRef.current.chart.getSVGForExport(),
                        width: 550,
                    },
                    {
                        text: '',
                        style: 'subHeader',
                    },
                    {
                        style: 'parametersTable',
                        table: {
                            widths: ['auto', '*', 'auto', '*', 'auto', '*'],
                            body: [
                                [
                                    { text: 'Wpłata początkowa', bold: true, style: 'parametersHeader' },
                                    {
                                        text: `${walletParameters.investmentAmount} ${content.investmentAmountCurrency}`,
                                        style: 'parametersText',
                                        noWrap: true,
                                    },
                                    { text: 'Wartość inwestycji', bold: true, style: 'parametersHeader' },
                                    {
                                        text: `${floorNumber(
                                            lastDayAssets.reduce((a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b))
                                        )} ${content.investmentAmountCurrency}`,
                                        style: 'parametersText',
                                        noWrap: true,
                                    },
                                    { text: 'Zmiana wartości inwestycji', bold: true, style: 'parametersHeader' },
                                    {
                                        text: `${formatNumber(
                                            ((lastDayAssets.reduce((a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b)) -
                                                walletParameters.investmentAmount) /
                                                parseFloat(walletParameters.investmentAmount)) *
                                                100,
                                            1
                                        )}%`,
                                        style: 'parametersText',
                                        noWrap: true,
                                    },
                                ],
                            ],
                        },
                        layout: 'noBorders',
                        pageBreak: 'after',
                    },
                    htmlToPdfmake(sanitizedLegalInfo),
                ],
                styles: {
                    header: {
                        fontSize: 14,
                    },
                    subHeader: {
                        fontSize: 10,
                        margin: [0, 15, 0, 15],
                    },
                    parametersTable: {
                        fontSize: 8,
                        fillColor: '#f5f6f7',
                    },
                    parametersHeader: {
                        margin: [7, 10, 0, 7],
                    },
                    parametersText: {
                        margin: [0, 10, 7, 7],
                    },
                    assetsTable: {
                        fontSize: 6,
                    },
                    assetsHeader: {
                        margin: [3, 7, 3, 7],
                        alignment: 'center',
                    },
                    assetsHeaderLeft: {
                        margin: [3, 7, 3, 7],
                    },
                    assetsText: {
                        margin: [3, 5, 3, 5],
                        alignment: 'center',
                    },
                    assetsTextLeft: {
                        margin: [5, 5, 5, 5],
                    },
                    footer: {
                        fontSize: 8,
                        margin: [0, 10, 0, 0],
                    },
                },
            };

            let date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().replace(/[^0-9]/g, '');
            pdfMake.createPdf(dd).download(`SuperBasket_${date.slice(0, date.length - 3)}.pdf`);
        }
    };

    return (
        <FormContainer title='Analizuj wyniki'>
            {chosenWallet && walletParameters && data && (
                <>
                    <SectionContainer title='Podsumowanie'>
                        {!isPhoneMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Okres inwestycji</Table.Td>
                                    <Table.Td>
                                        {content.investmentPeriods.filter((p) => +p.value === walletParameters.investmentPeriod)[0].label}
                                    </Table.Td>
                                    <Table.Td head>Data początku inwestycji</Table.Td>
                                    <Table.Td>{new Date(walletParameters.investmentStartDate).toLocaleDateString('pl-PL')}</Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                        {isPhoneMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Okres inwestycji</Table.Td>
                                    <Table.Td>{walletParameters.investmentPeriod} miesięcy</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Data początku inwestycji</Table.Td>
                                    <Table.Td>{new Date(walletParameters.investmentStartDate).toLocaleDateString('pl-PL')}</Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                    </SectionContainer>
                    <SectionContainer title={chosenWallet.name}>
                        <Table responsive>
                            <Table.Tr head>
                                <Table.Td></Table.Td>
                                {Object.keys(data.calculations.totalUnits).map((asset, index) => (
                                    <Table.Td alignCenter>
                                        {asset} ({chosenWallet.assets[index].proportion * 100}%)
                                    </Table.Td>
                                ))}
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>
                                    {`Wartość inwestycji na dzień ${new Date(
                                        Object.keys(data.calculations.iteration)[Object.keys(data.calculations.iteration).length - 1]
                                    ).toLocaleDateString('pl-PL')} (PLN)`}
                                </Table.Td>
                                {lastDayAssets &&
                                    lastDayAssets.map((asset, index) => index > 0 && <Table.Td alignCenter>{formatNumber(asset)}</Table.Td>)}
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td>Ilość nabytych jednostek w ramach Programu</Table.Td>
                                {Object.values(data.calculations.totalUnits).map((asset) => (
                                    <Table.Td alignCenter>{formatNumber(asset)}</Table.Td>
                                ))}
                            </Table.Tr>
                        </Table>
                    </SectionContainer>
                    <SectionContainer title='Przebieg inwestycji Super Basket'>
                        <div>
                            <AsyncLineChart options={areaChartOptions} ref={areaChartRef} constructorType={'stockChart'} />
                        </div>
                    </SectionContainer>
                    <SectionContainer>
                        {!isTabletMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Wpłata początkowa</Table.Td>
                                    <Table.Td>{`${formatNumber(walletParameters.investmentAmount)} ${content.investmentAmountCurrency}`}</Table.Td>
                                    <Table.Td head>Wartość inwestycji</Table.Td>
                                    <Table.Td>
                                        {`${
                                            lastDayAssets &&
                                            floorNumber(
                                                lastDayAssets.reduce((a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b))
                                            )
                                        } ${content.investmentAmountCurrency}`}
                                    </Table.Td>
                                    <Table.Td head>Zmiana wartości inwestycji</Table.Td>
                                    <Table.Td>
                                        {lastDayAssets &&
                                            formatNumber(
                                                ((lastDayAssets.reduce(
                                                    (a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b)
                                                ) -
                                                    walletParameters.investmentAmount) /
                                                    parseFloat(walletParameters.investmentAmount)) *
                                                    100,
                                                1
                                            )}
                                        %
                                    </Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                        {isTabletMode && (
                            <Table responsive>
                                <Table.Tr>
                                    <Table.Td head>Wpłata początkowa</Table.Td>
                                    <Table.Td>{`${formatNumber(walletParameters.investmentAmount)} ${content.investmentAmountCurrency}`}</Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Wartość inwestycji</Table.Td>
                                    <Table.Td>
                                        {`${
                                            lastDayAssets &&
                                            floorNumber(
                                                lastDayAssets.reduce((a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b))
                                            )
                                        } ${content.investmentAmountCurrency}`}
                                    </Table.Td>
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td head>Zmiana wartości inwestycji</Table.Td>
                                    <Table.Td>
                                        {lastDayAssets &&
                                            formatNumber(
                                                ((lastDayAssets.reduce(
                                                    (a, b) => formatNumberWithoutConversion(a) + formatNumberWithoutConversion(b)
                                                ) -
                                                    walletParameters.investmentAmount) /
                                                    parseFloat(walletParameters.investmentAmount)) *
                                                    100,
                                                1
                                            )}
                                        %
                                    </Table.Td>
                                </Table.Tr>
                            </Table>
                        )}
                    </SectionContainer>
                    <div className={styles.legalBoxContainer}>
                        <LegalInfoBox htmlData={data.legalInformation} responsiveIFrame={responsiveIFrame} />
                    </div>
                    <FormNavigations
                        disableSubmit={!data}
                        showBackButton={true}
                        backText='Poprzedni krok'
                        nextText='Zapisz PDF'
                        onBack={() => {
                            dispatch(changeSuperBasketStep(1));
                        }}
                        onNext={() => {
                            exportDataToPdf();
                        }}
                    />
                </>
            )}
        </FormContainer>
    );
}
