import * as Yup from 'yup';
import validation from '../../../validation.json';

export const validationWalletParameters = Yup.object({
    ratesOfReturn1: Yup.number().required(validation.validation.required),
    ratesOfReturn2: Yup.number().required(validation.validation.required),
    ratesOfReturn3: Yup.number().required(validation.validation.required),
    paymentFrequency: Yup.number().required(validation.validation.required),
    paymentAmount: Yup.number().required(validation.validation.required),
    investmentPeriod: Yup.number().required(validation.validation.required)
});