import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Table } from '../../../../common/Table/Table';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import SliderInput from '../../../../common/SliderInput/SliderInput';
import SelectInput from '../../../../common/SelectInput/SelectInput';
import FieldInput from '../../../../common/FieldInput/FieldInput';
import Error from '../../../../common/Error/Error';
import { formValues, saveFormValues, saveResults } from '../../../../../redux/slices/myPerspectiveWalletParametersFormSlice';
import { chosenWallet as wallet } from '../../../../../redux/slices/myPerspectiveFormSlice';
import { changeMyPerspectiveStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { fundsData as data } from '../../../../../redux/slices/myPerspectiveFormSlice';
import { validationWalletParameters } from './validation';
import { phoneBreakpoint, tabletBreakpoint } from '../../../../../helpers/responsiveBreakpoints';
import { useGetCalculatorResultsMutation } from '../../../../../redux/services/myPerspectiveFormApi';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import content from '../../content.json';
import contentValidation from '../../../validation.json';
import styles from './WalletParametersForm.module.scss';

export default function WalletParametersForm(props) {
    const { step, isLoading, setLoading } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });
    const isTabletMode = useMediaQuery({ maxWidth: tabletBreakpoint });
    const initValues = useSelector(formValues);
    const dispatch = useDispatch();
    const chosenWallet = useSelector(wallet);
    const fundsData = useSelector(data);
    const [globalError, setGlobalError] = useState();
    const [getResults, result] = useGetCalculatorResultsMutation();

    useEffect(() => {
        responsiveIFrame();
    }, []);

    const onFormSubmit = async (values) => {
        setLoading(true);
        setGlobalError('');

        dispatch(
            saveFormValues({
                ...values,
            })
        );

        // get results
        let ratesOfReturn = Object.entries(values)
            .filter((rate) => rate[0].includes('ratesOfReturn'))
            .map((rate) => rate[1]);

        const body = {
            ratesOfReturn: ratesOfReturn,
            paymentFrequency: values.paymentFrequency,
            paymentAmount: values.paymentAmount,
            investmentPeriod: values.investmentPeriod,
            walletId: +chosenWallet.id,
        };

        const response = await getResults(body);

        setLoading(false);

        if (response.error !== undefined) {
            setGlobalError(contentValidation.dataLoadError);
        } else {
            setGlobalError('');
            dispatch(saveResults(response.data));
            dispatch(changeMyPerspectiveStep(2));
        }
    };

    return (
        <FormContainer title='Podaj parametry funduszy'>
            <Formik enableReinitialize initialValues={{ ...initValues }} validationSchema={validationWalletParameters} onSubmit={onFormSubmit}>
                {({ errors, touched }) => (
                    <>
                        {chosenWallet && (
                            <Form>
                                {!isPhoneMode && !isTabletMode ? (
                                    <>
                                        <Table>
                                            <Table.Tr head>
                                                <Table.Td width='40%'>{chosenWallet.name}</Table.Td>
                                                {chosenWallet.assets.map((a) => (
                                                    <Table.Td width='20%' alignCenter key={a.name}>
                                                        {a.name}
                                                    </Table.Td>
                                                ))}
                                            </Table.Tr>
                                            <Table.Tr>
                                                <Table.Td>{content.walletRateOfReturnText}</Table.Td>
                                                {chosenWallet.assets.map((a, index) => (
                                                    <Table.Td key={a.name}>
                                                        <FieldWrapper
                                                            error={touched[`ratesOfReturn${index + 1}`] && errors[`ratesOfReturn${index + 1}`]}
                                                            errorTooltip
                                                            tooltipPosition='bottom'
                                                        >
                                                            <SelectInput name={`ratesOfReturn${index + 1}`} customField={Field}>
                                                                {content.ratesOfReturn.map((option) => {
                                                                    return (
                                                                        <option value={option.value} key={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </SelectInput>
                                                        </FieldWrapper>
                                                    </Table.Td>
                                                ))}
                                            </Table.Tr>
                                        </Table>
                                        <div className={styles.tableContainer}>
                                            <Table>
                                                <Table.Tr>
                                                    <Table.Td width='40%'>Pierwsza wpłata</Table.Td>
                                                    <Table.Td width='60%'>
                                                        {fundsData.firstPayment} {content.firstPaymentCurrency}
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td>Częstotliwość wpłat</Table.Td>
                                                    <Table.Td>
                                                        <div className={styles.selectContainer}>
                                                            <FieldWrapper
                                                                error={touched.paymentFrequency && errors.paymentFrequency}
                                                                errorTooltip
                                                                tooltipPosition='right'
                                                            >
                                                                <SelectInput name='paymentFrequency' customField={Field}>
                                                                    {content.paymentFrequencies.map((option) => {
                                                                        return (
                                                                            <option value={option.value} key={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </SelectInput>
                                                            </FieldWrapper>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td>Wysokość wpłat (w PLN)</Table.Td>
                                                    <Table.Td>
                                                        <div className={styles.sliderInputContainer}>
                                                            <div className={styles.sliderContainer}>
                                                                <SliderInput name='paymentAmount' min={100} max={10000} customField={Field} />
                                                            </div>
                                                            <div className={styles.numberContainer}>
                                                                <FieldWrapper
                                                                    error={touched.paymentAmount && errors.paymentAmount}
                                                                    errorTooltip
                                                                    tooltipPosition='right'
                                                                >
                                                                    <FieldInput
                                                                        type='number'
                                                                        name='paymentAmount'
                                                                        min={100}
                                                                        max={10000}
                                                                        customField={Field}
                                                                    />
                                                                </FieldWrapper>
                                                            </div>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td>Okres inwestycji (w latach)</Table.Td>
                                                    <Table.Td>
                                                        <div className={styles.sliderInputContainer}>
                                                            <div className={styles.sliderContainer}>
                                                                <SliderInput
                                                                    name='investmentPeriod'
                                                                    min={6}
                                                                    max={40}
                                                                    customField={Field}
                                                                    markText='lat'
                                                                />
                                                            </div>
                                                            <div className={styles.numberContainer}>
                                                                <FieldWrapper
                                                                    error={touched.investmentPeriod && errors.investmentPeriod}
                                                                    errorTooltip
                                                                    tooltipPosition='right'
                                                                >
                                                                    <FieldInput
                                                                        type='number'
                                                                        name='investmentPeriod'
                                                                        min={6}
                                                                        max={40}
                                                                        customField={Field}
                                                                    />
                                                                </FieldWrapper>
                                                            </div>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                            </Table>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Table>
                                            <Table.Tr>
                                                <Table.Td head>{chosenWallet.name}</Table.Td>
                                                <Table.Td>{content.walletRateOfReturnText}</Table.Td>
                                            </Table.Tr>
                                            {chosenWallet.assets.map((a, index) => (
                                                <Table.Tr key={a.name}>
                                                    <Table.Td head>{a.name}</Table.Td>
                                                    <Table.Td>
                                                        <FieldWrapper
                                                            error={touched[`ratesOfReturn${index + 1}`] && errors[`ratesOfReturn${index + 1}`]}
                                                            errorTooltip
                                                            tooltipPosition='bottom'
                                                        >
                                                            <SelectInput name={`ratesOfReturn${index + 1}`} customField={Field}>
                                                                {content.ratesOfReturn.map((option) => {
                                                                    return (
                                                                        <option value={option.value} key={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </SelectInput>
                                                        </FieldWrapper>
                                                    </Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table>
                                        <div className={styles.tableContainer}>
                                            <Table>
                                                <Table.Tr>
                                                    <Table.Td width='50%'>Pierwsza wpłata</Table.Td>
                                                    <Table.Td>
                                                        {fundsData.firstPayment} {content.firstPaymentCurrency}
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td width='50%'>Częstotliwość wpłat</Table.Td>
                                                    <Table.Td>
                                                        <div className={styles.selectContainer}>
                                                            <FieldWrapper
                                                                touched={touched}
                                                                error={touched.paymentFrequency && errors.paymentFrequency}
                                                                errorTooltip
                                                                tooltipPosition='bottom'
                                                            >
                                                                <SelectInput name='paymentFrequency' customField={Field}>
                                                                    {content.paymentFrequencies.map((option) => {
                                                                        return (
                                                                            <option value={option.value} key={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </SelectInput>
                                                            </FieldWrapper>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td colSpan='2'>Wysokość wpłat (w PLN)</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td colSpan='2'>
                                                        <div className={styles.sliderInputContainer}>
                                                            <div class={styles.mobileSliderContainer}>
                                                                <SliderInput name='paymentAmount' min={100} max={10000} customField={Field} />
                                                            </div>
                                                            <FieldWrapper
                                                                error={touched.paymentAmount && errors.paymentAmount}
                                                                errorTooltip
                                                                tooltipPosition='bottom'
                                                            >
                                                                <FieldInput
                                                                    type='number'
                                                                    name='paymentAmount'
                                                                    min={100}
                                                                    max={10000}
                                                                    customField={Field}
                                                                />
                                                            </FieldWrapper>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td colSpan='2'>Okres inwestycji (w latach)</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td colSpan='2'>
                                                        <div className={styles.sliderInputContainer}>
                                                            <div className={styles.mobileSliderContainer}>
                                                                <SliderInput
                                                                    name='investmentPeriod'
                                                                    min={6}
                                                                    max={40}
                                                                    customField={Field}
                                                                    markText='lat'
                                                                />
                                                            </div>
                                                            <FieldWrapper
                                                                error={touched.investmentPeriod && errors.investmentPeriod}
                                                                errorTooltip
                                                                tooltipPosition='bottom'
                                                            >
                                                                <FieldInput
                                                                    type='number'
                                                                    name='investmentPeriod'
                                                                    min={6}
                                                                    max={40}
                                                                    customField={Field}
                                                                />
                                                            </FieldWrapper>
                                                        </div>
                                                    </Table.Td>
                                                </Table.Tr>
                                            </Table>
                                        </div>
                                    </>
                                )}
                                <FormNavigations
                                    showBackButton={true}
                                    error={globalError}
                                    onBack={() => {
                                        dispatch(changeMyPerspectiveStep(0));
                                    }}
                                />
                            </Form>
                        )}
                        {!chosenWallet && <Error error={contentValidation.validation.dataLoadError} />}
                    </>
                )}
            </Formik>
        </FormContainer>
    );
}
