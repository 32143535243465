import React from 'react';

import { MyPerspectiveForm } from '../../components/forms/MyPerspective/MyPerspectiveForm';
import PageContainer from '../../components/common/PageContainer/PageContainer';

export default function MyPerspective() {
    return (
        <PageContainer>
            <MyPerspectiveForm />
        </PageContainer>
    );
}
