import clsx from 'clsx';
import React from 'react';

import styles from './ErrorTooltip.module.scss';

export default function ErrorTooltip(props) {
    const { error = '', position = 'bottom' } = props;

    return <div className={clsx(error && styles.error, !error && styles.hidden, error && position && styles['error--' + position])}>{error}</div>;
}
