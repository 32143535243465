import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: {
        ratesOfReturn1: 0.1,
        ratesOfReturn2: 0.1,
        ratesOfReturn3: 0.1,
        paymentFrequency: '',
        paymentAmount: 100,
        investmentPeriod: 6,
    },
    results: null,
};

const myPerspectiveWalletParametersFormSlice = createSlice({
    name: 'myPerspectiveWalletParametersForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => ({
            ...state,
            formValues: {
                ...state.formValues,
                ...payload,
            },
        }),
        saveResults: (state, { payload }) => {
            state.results = payload;
        },
    },
});

// selectors
export const formValues = (state) => state.myPerspectiveWalletParametersForm.formValues;
export const results = (state) => state.myPerspectiveWalletParametersForm.results;

// actions
export const { saveFormValues, saveResults } = myPerspectiveWalletParametersFormSlice.actions;

// reducer
export default myPerspectiveWalletParametersFormSlice.reducer;
