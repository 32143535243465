import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Error from '../../../../common/Error/Error';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import { Table } from '../../../../common/Table/Table';
import Calendar from '../../../../common/Calendar/Calendar';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import { formValues, saveFormValues, saveResults } from '../../../../../redux/slices/myWalletParametersFormSlice';
import { chosenFundsData } from '../../../../../redux/slices/myWalletFormSlice';
import {
    useGetNextStepResultsMutation,
    useGetFeeResultsMutation,
    useGetAnalysisResultsMutation,
} from '../../../../../redux/services/myWalletFormApi';
import { changeMyWalletStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import FieldInput from '../../../../common/FieldInput/FieldInput';
import { floorNumberWithoutConversion, formatNumber } from '../../../../../helpers/formatNumber';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import formValidation from '../../../validation.json';
import contentValidation from '../../../validation.json';
import content from '../../content.json';
import styles from './MyWalletParametersForm.module.scss';
import { Button } from '../../../../common/Button/Button';

export default function MyWalletParametersForm(props) {
    const { step, setLoading } = props;
    let initValues = useSelector(formValues);
    const dispatch = useDispatch();
    const fundsData = useSelector(chosenFundsData);
    const [globalError, setGlobalError] = useState();
    const [getNextStepResults] = useGetNextStepResultsMutation();
    const [getFeeResults] = useGetFeeResultsMutation();
    const [getAnalysisResults] = useGetAnalysisResultsMutation();
    const formRef = useRef(null);
    const [isUnitsInputActive, setIsUnitsInputActive] = useState(fundsData.reduce((obj, fund) => ({ ...obj, [fund.externalId]: false }), {}));
    const [isCalculateFeeDisabled, setIsCalculateFeeDisabled] = useState(true);

    useEffect(() => {
        responsiveIFrame();
    }, []);

    const fillInitialValues = () => {
        let values = {};
        let valObj = {};

        if (!initValues) {
            let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

            fundsData.forEach((fund) => {
                values[fund.externalId] = {
                    externalId: fund.externalId,
                    startCalculationDate: '',
                    endCalculationDate: yesterday.toISOString().split('T')[0],
                    investmentAmount: undefined,
                    fee: 0.0,
                    units: 0,
                    percentage: 0.0,
                };

                let unitsRule = isUnitsInputActive[fund.externalId]
                    ? Yup.number()
                          .positive()
                          .min(0.001, formValidation.validation.minValueError + ' 0.001')
                          .max(999999999.999, formValidation.validation.maxValueError + ' 999 999 999,999')
                          .required(formValidation.validation.required)
                    : Yup.number();

                valObj[fund.externalId] = Yup.object().shape({
                    startCalculationDate: Yup.string().required(formValidation.validation.required),
                    endCalculationDate: Yup.string().required(formValidation.validation.required),
                    investmentAmount: Yup.number()
                        .positive()
                        .min(1, formValidation.validation.minValueError + ' 1')
                        .max(999999999, formValidation.validation.maxValueError + ' 999 999 999')
                        .required(formValidation.validation.required),
                    units: unitsRule,
                });
            });
        } else {
            values = initValues;
            fundsData.forEach((fund) => {
                let unitsRule = isUnitsInputActive[fund.externalId]
                    ? Yup.number()
                          .min(0.001, formValidation.validation.minValueError + ' 0.001')
                          .max(999999999.999, formValidation.validation.maxValueError + ' 999 999 999,999')
                          .required(formValidation.validation.required)
                    : Yup.number();

                valObj[fund.externalId] = Yup.object().shape({
                    startCalculationDate: Yup.string().required(formValidation.validation.required),
                    endCalculationDate: Yup.string().required(formValidation.validation.required),
                    investmentAmount: Yup.number()
                        .min(1, formValidation.validation.minValueError + ' 1')
                        .required(formValidation.validation.required),
                    units: unitsRule,
                });
            });
        }

        let validationSchema = Yup.object().shape(valObj);

        return { initValues: values, validationSchema: validationSchema };
    };

    const onBlur = async () => {
        await formRef.current.validateForm().then(async (errors) => {
            if (Object.keys(errors).length === 0) {
                setGlobalError('');
                setLoading(true);
                let payload = Object.values(formRef.current.values).map((value) => ({
                    externalId: value.externalId,
                    investmentAmount: value.investmentAmount,
                    startCalculationDate: value.startCalculationDate,
                }));
                const response = await getNextStepResults(payload);
                setLoading(false);
                if (response.error !== undefined) {
                    setIsCalculateFeeDisabled(true);
                    if (response.error.status === 400)
                        setGlobalError('Brak dostępnej wyceny Funduszu dla wybranego dnia. Wybierz inną datę początku inwestycji.');
                    else setGlobalError(contentValidation.validation.dataLoadError);
                } else {
                    Object.entries(response.data).forEach(([key, fund]) => {
                        formRef.current.setFieldValue(`${key}.percentage`, fund.percentage);
                        formRef.current.setFieldValue(`${key}.priceValue`, fund.priceValue);
                        formRef.current.setFieldValue(`${key}.units`, floorNumberWithoutConversion(fund.units, 3));
                        formRef.current.setFieldValue(`${key}.fee`, 0.0);
                    });
                    setIsCalculateFeeDisabled(false);
                }
            }
        });
    };

    const onInitiateCalculateFee = async (fundId) => {
        await formRef.current.validateForm().then(async (errors) => {
            if (Object.keys(errors).length === 0) {
                setIsUnitsInputActive({ ...isUnitsInputActive, [fundId]: true });
            }
        });
    };

    const onSubmitCalculateFee = async (fundId) => {
        await formRef.current.validateForm().then(async (errors) => {
            if (Object.keys(errors).length === 0) {
                setGlobalError('');
                setLoading(true);
                let payload = {
                    externalId: fundId,
                    startCalculationDate: formRef.current.values[fundId].startCalculationDate,
                    investmentAmount: formRef.current.values[fundId].investmentAmount,
                    units: Math.round(formRef.current.values[fundId].units * 1000) / 1000,
                };
                const response = await getFeeResults(payload);
                setLoading(false);
                if (response.error !== undefined) {
                    if (response.error.status === 400) setGlobalError('Wprowadź poprawną ilość jednostek uczestnictwa.');
                    else setGlobalError(contentValidation.validation.dataLoadError);
                } else {
                    formRef.current.setFieldValue(`${fundId}.fee`, response.data.fee, false);
                    setIsUnitsInputActive({ ...isUnitsInputActive, [fundId]: false });
                }
            }
        });
    };

    const onFormSubmit = async (values) => {
        setLoading(true);
        setGlobalError('');

        dispatch(
            saveFormValues({
                ...values,
            })
        );

        const body = Object.values(values);

        const response = await getAnalysisResults(body);

        setLoading(false);

        if (response.error !== undefined) {
            if (response.error.status === 400)
                setGlobalError('Brak dostępnej wyceny Funduszu dla wybranego dnia. Wybierz inną datę zakończenia inwestycji.');
            else setGlobalError(contentValidation.validation.dataLoadError);
        } else {
            setGlobalError('');
            console.log(response);
            dispatch(saveResults(response.data));
            dispatch(changeMyWalletStep(2));
        }
    };

    return (
        <FormContainer title='Podaj parametry funduszy'>
            <Formik
                innerRef={formRef}
                enableReinitialize
                initialValues={fillInitialValues().initValues}
                validationSchema={fillInitialValues().validationSchema}
                onSubmit={onFormSubmit}
            >
                {({ errors, values, touched, setFieldValue }) => {
                    return (
                        <>
                            {fundsData && (
                                <Form>
                                    <div>{`${content.wallerParametersSubHeader} "${content.wallerParametersSubHeaderLinkText}".`}</div>
                                    <Table responsive className={styles.tableContainer} colorEveryTwo>
                                        <Table.Tr head>
                                            <Table.Td>Nazwa funduszu / subfunduszu</Table.Td>
                                            <Table.Td alignCenter>Kategoria jednostek</Table.Td>
                                            <Table.Td alignCenter>Data początku inwestycji</Table.Td>
                                            <Table.Td alignCenter>Data zakończenia inwestycji</Table.Td>
                                            <Table.Td alignCenter>Kwota zainwestowana</Table.Td>
                                            <Table.Td alignCenter>Ilość jednostek uczestnictwa</Table.Td>
                                            <Table.Td alignCenter>Procentowy udział w portfelu</Table.Td>
                                            <Table.Td alignCenter>Opłata manipulacyjna</Table.Td>
                                        </Table.Tr>
                                        {fundsData.map((fund) => (
                                            <>
                                                <Table.Tr key={fund.externalId}>
                                                    <Table.Td>{fund.name}</Table.Td>
                                                    <Table.Td alignCenter>{fund.class}</Table.Td>
                                                    <Table.Td alignCenter>
                                                        <FieldWrapper
                                                            error={errors[fund.externalId] && errors[fund.externalId].startCalculationDate}
                                                            errorTooltip
                                                            tooltipPosition='bottom'
                                                        >
                                                            <Calendar
                                                                disabled={isUnitsInputActive[fund.externalId]}
                                                                disableWeekends={true}
                                                                name={`startCalculationDate_${fund.externalId}`}
                                                                id={`startCalculationDate_${fund.externalId}`}
                                                                value={values[fund.externalId].startCalculationDate}
                                                                onChange={async (date) => {
                                                                    let startDate = '';
                                                                    if (date.length !== 0) {
                                                                        const offset = date[0].getTimezoneOffset();
                                                                        startDate = new Date(date[0].getTime() - offset * 60000)
                                                                            .toISOString()
                                                                            .split('T')[0];
                                                                    }
                                                                    await setFieldValue(`${fund.externalId}.startCalculationDate`, startDate);
                                                                    onBlur(setFieldValue);
                                                                }}
                                                                disableDates={function (date) {
                                                                    let yesterday = new Date().setDate(new Date().getDate() - 1);
                                                                    return date > yesterday;
                                                                }}
                                                                preText='od'
                                                            />
                                                        </FieldWrapper>
                                                    </Table.Td>
                                                    <Table.Td alignCenter>
                                                        <FieldWrapper
                                                            error={errors[fund.externalId] && errors[fund.externalId].endCalculationDate}
                                                            errorTooltip
                                                            tooltipPosition='bottom'
                                                        >
                                                            <Calendar
                                                                disableWeekends={true}
                                                                name={`endCalculationDate_${fund.externalId}`}
                                                                id={`endCalculationDate_${fund.externalId}`}
                                                                value={values[fund.externalId].endCalculationDate}
                                                                onChange={async (date) => {
                                                                    let endDate = '';
                                                                    if (date.length !== 0) {
                                                                        const offset = date[0].getTimezoneOffset();
                                                                        endDate = new Date(date[0].getTime() - offset * 60000)
                                                                            .toISOString()
                                                                            .split('T')[0];
                                                                    }
                                                                    await setFieldValue(`${fund.externalId}.endCalculationDate`, endDate);
                                                                }}
                                                                disableDates={function (date) {
                                                                    let yesterday = new Date().setDate(new Date().getDate() - 1);
                                                                    return date > yesterday;
                                                                }}
                                                                preText='od'
                                                            />
                                                        </FieldWrapper>
                                                    </Table.Td>
                                                    <Table.Td alignCenter>
                                                        <FieldWrapper
                                                            error={errors[fund.externalId] && errors[fund.externalId].investmentAmount}
                                                            errorTooltip
                                                            tooltipPosition='bottom'
                                                        >
                                                            <div className={styles.amountContainer}>
                                                                <FieldInput
                                                                    type='number'
                                                                    min={1}
                                                                    name={`investmentAmount_${fund.externalId}`}
                                                                    id={`investmentAmount_${fund.externalId}`}
                                                                    customField={Field}
                                                                    value={values[fund.externalId].investmentAmount}
                                                                    onChange={async (e) => {
                                                                        await setFieldValue(`${fund.externalId}.investmentAmount`, +e.target.value);
                                                                    }}
                                                                    onBlur={() => onBlur(setFieldValue)}
                                                                    disabled={isUnitsInputActive[fund.externalId]}
                                                                />
                                                                <div className={styles.currency}>{fund.currency}</div>
                                                            </div>
                                                        </FieldWrapper>
                                                    </Table.Td>
                                                    <Table.Td alignCenter>
                                                        {isUnitsInputActive[fund.externalId] ? (
                                                            <FieldWrapper
                                                                error={errors[fund.externalId] && errors[fund.externalId].units}
                                                                errorTooltip
                                                                tooltipPosition='bottom'
                                                            >
                                                                <FieldInput
                                                                    type='number'
                                                                    min={0.001}
                                                                    max={999999999.999}
                                                                    step={0.001}
                                                                    name={`units_${fund.externalId}`}
                                                                    id={`units_${fund.externalId}`}
                                                                    customField={Field}
                                                                    value={values[fund.externalId].units}
                                                                    onChange={async (e) => {
                                                                        await setFieldValue(`${fund.externalId}.units`, +e.target.value);
                                                                    }}
                                                                />
                                                            </FieldWrapper>
                                                        ) : (
                                                            values[fund.externalId].units && formatNumber(values[fund.externalId].units, 3)
                                                        )}
                                                    </Table.Td>
                                                    <Table.Td alignCenter>{Math.round(values[fund.externalId].percentage * 100)}%</Table.Td>
                                                    <Table.Td alignCenter>{formatNumber(values[fund.externalId].fee)}%</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td colSpan='4' className={styles.feeRow}></Table.Td>
                                                    <Table.Td colSpan='4' alignCenter className={styles.feeRow}>
                                                        {isUnitsInputActive[fund.externalId] ? (
                                                            <Button
                                                                type='button'
                                                                view='text'
                                                                className={styles.calcFeeBtn}
                                                                onClick={() => onSubmitCalculateFee(fund.externalId)}
                                                            >
                                                                {content.wallerParametersFeeSubmitText}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                type='button'
                                                                view='text'
                                                                className={styles.calcFeeBtn}
                                                                onClick={() => onInitiateCalculateFee(fund.externalId)}
                                                                disabled={isCalculateFeeDisabled}
                                                            >
                                                                {content.wallerParametersSubHeaderLinkText}
                                                            </Button>
                                                        )}
                                                    </Table.Td>
                                                </Table.Tr>
                                            </>
                                        ))}
                                    </Table>
                                    <FormNavigations
                                        showBackButton={true}
                                        onBack={() => {
                                            dispatch(changeMyWalletStep(0));
                                        }}
                                        error={globalError}
                                    />
                                </Form>
                            )}
                            {!fundsData && <Error error={contentValidation.validation.dataLoadError} />}
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
}
