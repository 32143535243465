import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../Button/Button';
import Error from '../Error/Error';
import styles from './FormNavigations.module.scss';

export default function FormNavigations(props) {
    const {
        showBackButton = true,
        error = null,
        onBack = () => {},
        onNext,
        nextText = 'Następny krok',
        backText = 'Poprzedni krok',
        disableSubmit = false,
    } = props;

    const eventOnBack = (e) => {
        e.preventDefault();
        onBack();
    };

    const eventOnNext = (e) => {
        e.preventDefault();
        onNext();
    };

    return (
        <div className={styles.navigationsContainer}>
            {error && (
                <div className={styles.errorContainer}>
                    <Error error={error} />
                </div>
            )}
            {showBackButton && (
                <Button view='secondary' type='button' className={clsx(styles.link, styles.back)} onClick={(e) => eventOnBack(e)}>
                    {backText}
                </Button>
            )}

            <div className={showBackButton ? styles.btnOffset : undefined}>
                {typeof onNext === 'function' ? (
                    <Button type='button' disabled={disableSubmit} onClick={(e) => eventOnNext(e)}>
                        {nextText}
                    </Button>
                ) : (
                    <Button type='submit' disabled={disableSubmit}>
                        {nextText}
                    </Button>
                )}
            </div>
        </div>
    );
}
