import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './apiConstants';

export const myPerspectiveFormApi = createApi({
    reducerPath: 'myPerspectiveFormApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getFirstStepData: builder.query({
            query: () => `/my-perspective-calc/first-step`,
        }),
        getCalculatorResults: builder.mutation({
            query: ({ ratesOfReturn, paymentFrequency, paymentAmount, investmentPeriod, walletId }) => ({
                url: '/my-perspective-calc/last-step',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    ratesOfReturn: ratesOfReturn,
                    paymentFrequency: paymentFrequency,
                    paymentAmount: paymentAmount,
                    investmentPeriod: investmentPeriod,
                    walletId: walletId,
                },
            }),
        }),
    }),
});

export const { useGetFirstStepDataQuery, useGetCalculatorResultsMutation } = myPerspectiveFormApi;
