import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './apiConstants';

export const myWalletFormApi = createApi({
    reducerPath: 'myWalletFormApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getFirstStepData: builder.query({
            query: () => `/my-wallet-calc/first-step`,
        }),
        getNextStepResults: builder.mutation({
            query: (payload) => ({
                url: '/my-wallet-calc/next-step',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: payload,
            }),
        }),
        getFeeResults: builder.mutation({
            query: (payload) => ({
                url: '/my-wallet-calc/fee',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: payload,
            }),
        }),
        getAnalysisResults: builder.mutation({
            query: (payload) => ({
                url: '/my-wallet-calc/last-step',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: payload,
            }),
        }),
    }),
});

export const { useGetFirstStepDataQuery, useGetNextStepResultsMutation, useGetFeeResultsMutation, useGetAnalysisResultsMutation } = myWalletFormApi;
