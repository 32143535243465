import React from 'react';
import Loader from '../GlobalLoader/Loader';

const Chart = React.lazy(() => {
    return import('./Chart').then(({ default: Component }) => {
        return {
            default: React.forwardRef((props, ref) => <Component ref={ref} {...props} />),
        };
    });
});

export const AsyncLineChart = React.forwardRef((props, ref) => {
    const { options, standardColors, ...rest } = props;
    const chartOptions = standardColors
        ? {
              ...options,
              credits: {
                  enabled: false,
              },
          }
        : {
              ...options,
              credits: {
                  enabled: false,
              },
              colors: ['#053f6f', '#464646', '#c3c3c3', '#d41817'],
          };

    const loader = (
        <div>
            <Loader isLoading={true} />
        </div>
    );

    return (
        <React.Suspense fallback={loader}>
            <Chart options={chartOptions} ref={ref} {...rest} />
        </React.Suspense>
    );
});
