import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    myPerspectiveStep: 0,
    superBasketStep: 0,
    myWalletStep: 0,
};

const calculatorsStepsSlice = createSlice({
    name: 'calculatorsSteps',
    initialState,
    reducers: {
        changeMyPerspectiveStep(state, { payload }) {
            return {
                ...state,
                myPerspectiveStep: payload,
            };
        },
        changeSuperBasketStep(state, { payload }) {
            return {
                ...state,
                superBasketStep: payload,
            };
        },
        changeMyWalletStep(state, { payload }) {
            return {
                ...state,
                myWalletStep: payload,
            };
        },
    },
});

export const myPerspectiveCurrentStep = (state) => state.calculatorsSteps.myPerspectiveStep;
export const superBasketCurrentStep = (state) => state.calculatorsSteps.superBasketStep;
export const myWalletCurrentStep = (state) => state.calculatorsSteps.myWalletStep;

export const { changeMyPerspectiveStep, changeSuperBasketStep, changeMyWalletStep } = calculatorsStepsSlice.actions;
export default calculatorsStepsSlice.reducer;
