import clsx from 'clsx';
import React from 'react';

import styles from './RadioBox.module.scss';

export const RadioBox = (props) => {
    const { id, error = false, Field, children } = props;

    const renderField = () => {
        return Field;
    };

    return (
        <label htmlFor={id} className={clsx(styles.container, error && styles['container--error'])}>
            {renderField()}
            <span className={styles.customCheck} />
            <div className={styles.label}>{children}</div>
        </label>
    );
};
