import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { RadioBox } from '../../../../common/RadioBox/RadioBox';
import Error from '../../../../common/Error/Error';
import { Table } from '../../../../common/Table/Table';
import { FormContainer } from '../../../../common/FormContainer/FormContainer';
import FormNavigations from '../../../../common/FormNavigations/FormNavigations';
import FieldWrapper from '../../../../common/FieldWrapper/FieldWrapper';
import { useGetFirstStepDataQuery } from '../../../../../redux/services/myPerspectiveFormApi';
import { formValues, saveFormValues, saveFundsData } from '../../../../../redux/slices/myPerspectiveFormSlice';
import { validationChooseWallet } from './validation';
import { changeMyPerspectiveStep } from '../../../../../redux/slices/calculatorsStepsSlice';
import { responsiveIFrame } from '../../../../../helpers/responsiveIFrame';
import content from '../../../validation.json';
import styles from './ChooseWalletForm.module.scss';

export default function ChooseWalletForm(props) {
    const { step, setLoading } = props;
    const initValues = useSelector(formValues);
    const { data, isLoading: isDataLoading, isSuccess, isError } = useGetFirstStepDataQuery();
    const dispatch = useDispatch();

    const onFormSubmit = (values) => {
        dispatch(
            saveFormValues({
                ...values,
            })
        );

        const chosenWallet = data.wallets.filter((w) => w.id === +values.walletId)[0];

        dispatch(saveFundsData({ fundsData: data, chosenWallet: chosenWallet }));
        dispatch(changeMyPerspectiveStep(1));
    };

    useEffect(() => {
        if (isDataLoading) {
            setLoading(true);
        } else if (isSuccess || isError) {
            setLoading(false);
            responsiveIFrame();
        }
    }, [isDataLoading, isSuccess, isError]);

    return (
        <FormContainer customClass="MyPerspectiveFirstStep" title='Wybierz portfel'>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={validationChooseWallet}
                onSubmit={onFormSubmit}
                isInitialValid={false}
            >
                {({ errors, values, isValid }) => {
                    return (
                        <>
                            {data && (
                                <Form>
                                    <FieldWrapper error={errors.walletId}>
                                        {data.wallets.map((wallet, index) => (
                                            <div key={wallet.name} className={styles.walletContainer}>
                                                <RadioBox
                                                    id={`walletId_${index}`}
                                                    Field={
                                                        <Field
                                                            type='radio'
                                                            id={`walletId_${index}`}
                                                            name='walletId'
                                                            value={wallet.id}
                                                            checked={+values.walletId === wallet.id}
                                                        />
                                                    }
                                                >
                                                    <div className={styles.walletLabel}>{wallet.name}</div>
                                                </RadioBox>

                                                <div className={styles.tableContainer}>
                                                    <Table greyFirst responsive>
                                                        <Table.Tr head>
                                                            <Table.Td></Table.Td>
                                                            <Table.Td width='70%' colSpan='5' alignCenter>
                                                                Proporcje podziału wpłat
                                                            </Table.Td>
                                                        </Table.Tr>
                                                        <Table.Tr head>
                                                            <Table.Td width='30%'>Czas do końca programu [lata]:</Table.Td>
                                                            <Table.Td alignCenter>1 i mniej</Table.Td>
                                                            <Table.Td alignCenter>powyżej 1 do 3</Table.Td>
                                                            <Table.Td alignCenter>powyżej 3 do 5</Table.Td>
                                                            <Table.Td alignCenter>powyżej 5 do 10</Table.Td>
                                                            <Table.Td alignCenter>więcej niż 10</Table.Td>
                                                        </Table.Tr>
                                                        {wallet.assets.map((asset) => (
                                                            <Table.Tr key={asset.name}>
                                                                <Table.Td>{asset.name}</Table.Td>
                                                                {Object.values(asset.proportions).map((p, index) => (
                                                                    <Table.Td key={p + index} alignCenter>
                                                                        {p * 100}%
                                                                    </Table.Td>
                                                                ))}
                                                            </Table.Tr>
                                                        ))}
                                                    </Table>
                                                </div>
                                            </div>
                                        ))}
                                    </FieldWrapper>
                                    <FormNavigations isFormValid={isValid} showBackButton={false} />
                                </Form>
                            )}
                            {isError && <Error error={content.validation.dataLoadError} />}
                        </>
                    );
                }}
            </Formik>
        </FormContainer>
    );
}
