import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: {
        investmentPeriod: '',
        investmentAmount: 5000,
        investmentStartDate: new Date().getDay() === 0 || new Date().getDay() === 6 ? '' : new Date().toISOString().split('T')[0],
    },
    results: null,
};

const superBasketWalletParametersFormSlice = createSlice({
    name: 'superBasketWalletParametersForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => ({
            ...state,
            formValues: {
                ...state.formValues,
                ...payload,
            },
        }),
        saveResults: (state, { payload }) => {
            state.results = payload;
        },
    },
});

// selectors
export const formValues = (state) => state.superBasketWalletParametersForm.formValues;
export const results = (state) => state.superBasketWalletParametersForm.results;

// actions
export const { saveFormValues, saveResults } = superBasketWalletParametersFormSlice.actions;

// reducer
export default superBasketWalletParametersFormSlice.reducer;
