import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: null,
    results: null,
};

const myWalletParametersFormSlice = createSlice({
    name: 'myWalletParametersForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => {
            state.formValues = payload;
        },
        saveResults: (state, { payload }) => {
            state.results = payload;
        },
    },
});

// selectors
export const formValues = (state) => state.myWalletParametersForm.formValues;
export const results = (state) => state.myWalletParametersForm.results;

// actions
export const { saveFormValues, saveResults } = myWalletParametersFormSlice.actions;

// reducer
export default myWalletParametersFormSlice.reducer;
