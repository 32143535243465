import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: {
        chosenFundsExternalIds: [],
    },
    chosenFundsData: null,
};

const myWalletFormSlice = createSlice({
    name: 'myWalletForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => ({
            ...state,
            formValues: {
                ...state.formValues,
                ...payload,
            },
        }),
        saveChosenFundsData: (state, { payload }) => {
            state.chosenFundsData = payload;
        },
    },
});

// selectors
export const formValues = (state) => state.myWalletForm.formValues;
export const chosenFundsData = (state) => state.myWalletForm.chosenFundsData;

// actions
export const { saveFormValues, saveChosenFundsData } = myWalletFormSlice.actions;

// reducer
export default myWalletFormSlice.reducer;
