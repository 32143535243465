import React from 'react';
import { BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';
import { routes } from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './App.module.scss';
import '../src/styles/custom/customStyles.scss'

export default function App() {
    return (
        <Router>
            <main className={styles.main}>
                <Routes>
                    {routes.map((item) => {
                        return item.component && <Route key={item.name} exact path={item.path} element={<item.component />} />;
                    })}
                </Routes>
            </main>
        </Router>
    );
}
