import { Link } from "react-router-dom";

export default function Home() {
    return (
        <>
            <h2><Link to="/my-perspective-calc">Moja Perspektywa</Link></h2>
            <h2><Link to="/my-perspective-calc-iframe">Moja Perspektywa IFrame</Link></h2>
            <h2><Link to="/super-basket-calc">Super Basket</Link></h2>
            <h2><Link to="/super-basket-calc-iframe">Super Basket IFrame</Link></h2>
            <h2><Link to="/my-wallet-calc">My Wallet</Link></h2>
            <h2><Link to="/my-wallet-calc-iframe">My Wallet IFrame</Link></h2>
        </>
    );
}
