import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formValues: {
        walletId: '',
    },
    fundsData: null,
    chosenWallet: null,
};

const myPerspectiveFormSlice = createSlice({
    name: 'myPerspectiveForm',
    initialState,
    reducers: {
        saveFormValues: (state, { payload }) => ({
            ...state,
            formValues: {
                ...state.formValues,
                ...payload,
            },
        }),
        saveFundsData: (state, { payload }) => {
            state.fundsData = payload.fundsData;
            state.chosenWallet = payload.chosenWallet;
        },
    },
});

// selectors
export const formValues = (state) => state.myPerspectiveForm.formValues;
export const fundsData = (state) => state.myPerspectiveForm.fundsData;
export const chosenWallet = (state) => state.myPerspectiveForm.chosenWallet;

// actions
export const { saveFormValues, saveFundsData } = myPerspectiveFormSlice.actions;

// reducer
export default myPerspectiveFormSlice.reducer;
