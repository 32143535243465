import React from 'react';
import Loader from '../GlobalLoader/Loader';

const Chart = React.lazy(() => {
    return import('./Chart').then(({ default: Component }) => {
        return {
            default: React.forwardRef((props, ref) => <Component ref={ref} {...props} />),
        };
    });
});

export const AsyncPieChart = React.forwardRef((props, ref) => {
    const { options, ...rest } = props;
    const chartOptions = {
        ...options,
        credits: {
            enabled: false,
        },
        colors: ['#d41817', '#5b5b5b', '#bbbbbb', '#063764'],
    };

    const loader = (
        <div>
            <Loader isLoading={true} />
        </div>
    );

    return (
        <React.Suspense fallback={loader}>
            <Chart options={chartOptions} ref={ref} {...rest} />
        </React.Suspense>
    );
});
