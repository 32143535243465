import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './apiConstants';

export const superBasketFormApi = createApi({
    reducerPath: 'superBasketFormApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getFirstStepData: builder.query({
            query: () => `/super-basket-calc/first-step`,
        }),
        getCalculatorResults: builder.mutation({
            query: ({ investmentAmount, investmentPeriod, investmentStartDate, walletId }) => ({
                url: '/super-basket-calc/last-step',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    investmentAmount: investmentAmount,
                    investmentPeriod: investmentPeriod,
                    investmentStartDate: investmentStartDate,
                    walletId: walletId,
                },
            }),
        }),
    }),
});

export const { useGetFirstStepDataQuery, useGetCalculatorResultsMutation } = superBasketFormApi;
