import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { phoneBreakpoint } from '../../../helpers/responsiveBreakpoints';
import { MobileStepItem } from './StepItem/MobileStepItem';
import { StepItem } from './StepItem/StepItem';
import styles from './Steps.module.scss';

export const Steps = (props) => {
    const { structure, step } = props;
    const isPhoneMode = useMediaQuery({ maxWidth: phoneBreakpoint });

    return (
        <div className={styles.steps}>
            {!isPhoneMode && (
                <ul className={styles.stepsList}>
                    {structure.map((item, index) => {
                        return <StepItem item={item} stepIndex={index} currentStep={step} data={structure} key={item.name} />;
                    })}
                </ul>
            )}
            {isPhoneMode && <MobileStepItem item={structure[step]} stepIndex={step} currentStep={step} data={structure}/>}
        </div>
    );
};
