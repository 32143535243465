import React from 'react';
import styles from './FormContainer.module.scss';
import clsx from 'clsx';
export const FormContainer = (props) => {
    const { children, title , customClass } = props;
    return (
        <div className={clsx(styles.FormContainer, customClass)} >
            <div className={styles.formTitle}>{title}</div>
            {children}
        </div>
    );
};
