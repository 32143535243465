import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Steps } from '../../../components/common/Steps/Steps';
import MyWalletChooseWalletForm from './steps/MyWalletChooseWalletForm/MyWalletChooseWalletForm';
import MyWalletParametersForm from './steps/MyWalletParametersForm/MyWalletParametersForm';
import MyWalletResultsAnalysis from './steps/MyWalletResultsAnalysis/MyWalletResultsAnalysis';
import { structure } from '../../../stepsStructure';
import { myWalletCurrentStep, changeMyWalletStep } from '../../../redux/slices/calculatorsStepsSlice';
import GlobalLoader from '../../common/GlobalLoader/GlobalLoader';
import styles from './MyWalletForm.module.scss';

export const MyWalletForm = () => {
    const step = useSelector(myWalletCurrentStep);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            changeMyWalletStep(0);
        };
    }, []);

    return (
        <div className={styles.formContainer}>
            <GlobalLoader isLoading={isLoading} />
            <div className={styles.calcHeader}>Kalkulator Mój Portfel</div>
            <Steps structure={structure.superBasket} step={step} />
            {step === 0 && <MyWalletChooseWalletForm step={step} isLoading={isLoading} setLoading={setLoading} />}
            {step === 1 && <MyWalletParametersForm step={step} isLoading={isLoading} setLoading={setLoading} />}
            {step === 2 && <MyWalletResultsAnalysis step={step} isLoading={isLoading} setLoading={setLoading} />}
        </div>
    );
};
