import React from 'react';

import PageContainer from '../../components/common/PageContainer/PageContainer';
import { SuperBasketForm } from '../../components/forms/SuperBasketForm/SuperBasketForm';

export default function SuperBasket() {
    return (
        <PageContainer>
            <SuperBasketForm />
        </PageContainer>
    );
}
