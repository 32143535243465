import React from 'react';
import clsx from 'clsx';

import styles from './StepItem.module.scss';

export const StepItem = (props) => {
    const { item, currentStep, data, stepIndex } = props;

    const isCurrent = currentStep === stepIndex;
    const isPassed = stepIndex < currentStep;

    return (
        <>
            <li className={clsx(styles.stepItem, isCurrent && styles['stepItem--active'], isPassed && styles['stepItem--passed'])}>
                <div>
                    <div className={styles.stepNumber}>KROK {stepIndex + 1}</div>
                    <div className={styles.stepName}>{item.name}</div>
                </div>
            </li>
            {stepIndex < data.length - 1 && <div className={styles.arrow}></div>}
        </>
    );
};
